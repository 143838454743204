$(document).on('turbolinks:load', function() {

	if ( document.querySelector('body.notifications') ) {

    var table = $('#dtUserEmailSettings').DataTable({
			columns: [
				{data: "id"},
        {data: "site_name"},
				{data: "inspection_reports"},
        {data: "field_proposals"},
        {data: "summary"}
	    ],
			columnDefs: [
				{
					targets: 0,
					checkboxes: {
						selectRow: true
					},
					orderable: false
				}
      ],
      select: "multi",
			stateSave: true

    });

		// Handle form submission event
		$('form#user_notifications').on('submit', function(e){
		  var form = this;

		  var rows_selected = table.column(0).checkboxes.selected();

		  // Iterate over all selected checkboxes
		  $.each(rows_selected, function(index, rowId){
		     // Create a hidden element
		     $(form).append(
		         $('<input>')
		            .attr('type', 'hidden')
		            .attr('name', 'user_site_ids[]')
		            .val(rowId)
		     );
		  });
		});

  }

})
