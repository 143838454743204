$(document).on("turbolinks:load", function() {
  if ( document.querySelector('body.grading_log_entries.new, body.grading_log_entries.edit, body.grading_log_entries') ) {

    $("#grading_log_entry_activity_description").editableSelect();

    document.getElementById("grading_log_entry_stabilization_required").addEventListener("change", function (e) {
      toggleGradingLogStabilization()
    })

    toggleGradingLogStabilization()

    function toggleGradingLogStabilization () {
      console.log("toggle")
      if (document.getElementById("grading_log_entry_stabilization_required").checked) {
        document.getElementById("grading-log-entry-stabilization-row").style.display = ""
        document.getElementById("grading_log_entry_stabilization_description").value = ""
      } else {
        document.getElementById("grading-log-entry-stabilization-row").style.display = "none"
        document.getElementById("grading_log_entry_stabilization_description").value = "N/A"
      }
    }


  }
})
