$(document).on("turbolinks:load", function(){
    $("#new-company-area").hide();
    $("#existing-site-area").hide();
    $("#new-company").on("click", function(){
      $("#new-company-area").show();
      $("#existing-site-area").hide();
    });
    $("#existing-site").on("click", function(){
      $("#new-company-area").hide();
      $("#existing-site-area").show();
    });
});
