$(document).on("turbolinks:load", function () {
  $('#dtWorkOrders').DataTable({
    dom: 'P<"d-flex justify-content-between"fBl>rtip',
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [3,4,5,7,8,10,11],
      layout: 'columns-4'
    },
    columnDefs: [
      {
        targets: [10,11],
        visible: false
      }
    ],
    order : [
      [0, "desc"]
    ]
  });

  if ( document.querySelector('body.work_orders.proposals') ) {
    // https://stackoverflow.com/questions/36217098/checkboxes-only-work-on-first-page-datatables-rails
    var work_order_assignments_table = $('#dtWorkOrdersProposals').DataTable({
      dom: 'P<"d-flex justify-content-between"fBl>rtip',
      searchPanes: {
        initCollapsed: true,
        cascadePanes: true,
        columns: [2,3,6,8,9],
        layout: 'columns-4'
      },
      columns: [
				{data: "id"},
        {data: "added_to_task"},
        {data: "email"},
        {data: "state"},
        {data: "proposal_id"},
        {data: "work_order_id"},
        {data: "category"},
        {data: "date"},
        {data: "company"},
        {data: "site_name"},
        {data: "description"},
        {data: "quantity"},
        {data: "quantity_denom"},
        {data: "unit_price"},
        {data: "total"}
	    ],
      columnDefs: [
        {
          searchPanes: {
              show: true
          },
          targets: [2,3,6,8,9]
         },
         {
          targets: 0,
          checkboxes: {
              selectRow: true
            }
         }
      ],
      select: 'multi',
			order: [
				[5, "asc"]
			],
      stateSave: false
    });

    // Handle form submission event
		$('form').on('submit', function(e){
		  console.log("click");
      var form = this;
		  var rows_selected = work_order_assignments_table.column(0).checkboxes.selected();
		  // Iterate over all selected checkboxes
		  $.each(rows_selected, function(index, rowId){
		     // Create a hidden element
		     $(form).append(
		         $('<input>')
		            .attr('type', 'hidden')
		            .attr('name', 'ids[]')
		            .val(rowId)
		     );
		  });
		});
  }

  if ( document.querySelector('body.work_orders.inspections') ) {
    console.log("Hello")
    // https://stackoverflow.com/questions/36217098/checkboxes-only-work-on-first-page-datatables-rails
    var work_order_assignments_table = $('#dtWorkOrdersInspections').DataTable({
      dom: 'P<"d-flex justify-content-between"fBl>rtip',
      searchPanes: {
        initCollapsed: true,
        cascadePanes: true,
        columns: [2,3,6,8,9],
        layout: 'columns-4'
      },
      columns: [
				{data: "id"},
        {data: "added_to_task"},
        {data: "email"},
        {data: "state"},
        {data: "proposal_id"},
        {data: "work_order_id"},
        {data: "category"},
        {data: "date"},
        {data: "company"},
        {data: "site_name"},
        {data: "description"},
        {data: "quantity"},
        {data: "quantity_denom"},
        {data: "unit_price"},
        {data: "total"}
	    ],
      columnDefs: [
         {
            searchPanes: {
                show: true
            },
            targets: [2,3,6,8,9]
         },
         {
          targets: 0,
          checkboxes: {
              selectRow: true
            }
         }
        //  {
 				// 	targets: 5,
 				// 	visible: false
 				// },
      ],
      select: 'multi',
			order: [
				[5, "asc"]
			],
      stateSave: false
    });

    // Handle form submission event
		$('form').on('submit', function(e){
		  console.log("click");
      var form = this;
		  var rows_selected = work_order_assignments_table.column(0).checkboxes.selected();
		  // Iterate over all selected checkboxes
		  $.each(rows_selected, function(index, rowId){
		     // Create a hidden element
		     $(form).append(
		         $('<input>')
		            .attr('type', 'hidden')
		            .attr('name', 'ids[]')
		            .val(rowId)
		     );
		  });
		});
  }

  if ( document.querySelector('body.work_orders.index') ) {
    console.log("Hello")
    // https://stackoverflow.com/questions/36217098/checkboxes-only-work-on-first-page-datatables-rails
    var work_order_assignments_table = $('#dtWorkOrdersAll').DataTable({
      dom: 'P<"d-flex justify-content-between"fBl>rtip',
      searchPanes: {
        initCollapsed: true,
        cascadePanes: true,
        columns: [2,3,6,8,9],
        layout: 'columns-4'
      },
      columns: [
				{data: "id"},
        {data: "added_to_task"},
        {data: "email"},
        {data: "state"},
        {data: "proposal_id"},
        {data: "work_order_id"},
        {data: "category"},
        {data: "date"},
        {data: "company"},
        {data: "site_name"},
        {data: "description"},
        {data: "quantity"},
        {data: "quantity_denom"},
        {data: "unit_price"},
        {data: "total"}
	    ],
      columnDefs: [
        {
          searchPanes: {
              show: true
          },
          targets: [2,3,6,8,9]
         },
         {
          targets: 0,
          checkboxes: {
              selectRow: true
            }
         }
        //  {
 				// 	targets: 5,
 				// 	visible: false
 				// },
      ],
      select: 'multi',
			order: [
				[5, "asc"]
			],
      stateSave: false
    });

    // Handle form submission event
		$('form').on('submit', function(e){
		  console.log("click");
      var form = this;
		  var rows_selected = work_order_assignments_table.column(0).checkboxes.selected();
		  // Iterate over all selected checkboxes
		  $.each(rows_selected, function(index, rowId){
		     // Create a hidden element
		     $(form).append(
		         $('<input>')
		            .attr('type', 'hidden')
		            .attr('name', 'ids[]')
		            .val(rowId)
		     );
		  });
		});
  }


  $('#dtWorkOrderIndexProposals').DataTable({
    dom: 'P<"d-flex justify-content-between"fBl>rtip',
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [2,3,4,5],
      layout: 'columns-4'
    },
    columnDefs: [
      {
        targets: [0,2,3,4,5,6,7,8,9,10,11],
        orderable: false
      },
    ],
    rowsGroup: [1, 0, 2, 3, 4, 5, 6, 10, 11],
    order : [
      [1, "desc"]
    ]
  });

  $('#dtMissedRainEvents').DataTable({
    dom: 'P<"d-flex justify-content-between"fBl>rtip',
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [2,4,5,7],
      layout: 'columns-4'
    },
    columnDefs: [
      {
        targets: [7],
        visible: false
      },
    ],
    order: [
      [0, "desc"]
    ]
  });

  // https://stackoverflow.com/questions/36217098/checkboxes-only-work-on-first-page-datatables-rails
  var table = $('#dtPendingRainInspections').DataTable({
    // processing: true,
    // serverSide: true,
    // ajax: {
    //   url: $('#dtProposalsAbattoir').data('source'),
    //   type: 'POST'
    // },
    // pagingType: "full_numbers",
    // columns: [
    //   {data: "id"},
    //   {data: "creator"},
    //   {data: "site/project name"},
    // ],
    columnDefs: [
      {
        targets: 0,
        checkboxes: {
          selectRow: false,
          selectAllPages: false
        }
      }
    ],
    // select: {
    //   style: "multi"
    // },
    order: [
      [1, "asc"]
    ]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });


  // Handle form submission event
  $('form').on('submit', function(e){
    var form = this;

    var rows_selected = table.column(0).checkboxes.selected();

    // Iterate over all selected checkboxes
    $.each(rows_selected, function(index, rowId){
       // Create a hidden element
       $(form).append(
           $('<input>')
              .attr('type', 'hidden')
              .attr('name', 'ids[]')
              .val(rowId)
       );
    });
  });
});
