toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "md-toast-bottom-full-width",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": 300,
  "hideDuration": 1000,
  "timeOut": 5000,
  "extendedTimeOut": 1000,
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

/* Leaflet - Resize map on tabbed page */
// $(document).on("turbolinks:load", function() {
// 	$("a[href='#panel666']").on('shown.bs.tab', function(){
// 		// L.Util.requestAnimFrame(map.invalidateSize,map,!1,map._container);
//     console.log("Resize map");
//     map.invalidateSize();
// 	});

//   // update site nav list to highlight current page
//   document.getElementsByClassName('list-group-item').forEach(function (item) {
//     if (item.href == window.location.href) {
//       item.classList.add("active")
//     } else {
//       item.classList.remove("active")
//     }
//   });
// });


// Reveal forms when Add button clicked on Site page
$("#add-contact").on("click", function(){
  $(window).trigger('resize');
});

// Preview image on file upload
function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function (e) {
      $(input).closest(".row").find("img")
        .attr('src', e.target.result)
        .width('auto')
        .height(200);
    }
    reader.readAsDataURL(input.files[0]);
  };
};

