$(document).on("turbolinks:load", function() {

    if ( document.querySelector('body.swpp_inspections.new') ) {
    }

    if ( document.querySelector('body.swpp_inspections.show' ) ) {
        console.log(document.getElementById("swpp").dataset.swpp);
        const event = new Event('resize');

        const form = document.getElementById("finalize");
        if (form) form.addEventListener('click', updateFormBeforeSubmit);

        if(document.getElementById("swpp").dataset.swpp != "swpp"){
            document.getElementById("finalize").style.visibility = 'visible'; 
        }
        if(document.getElementById("swpp").dataset.swpp == "swpp"){
            const newColor = "#FFFF00"
            const oldColor = "#ff0000"
            const imageRes = 0.75
            //console.log("swpp!");
        
            document.getElementById("finalize").style.visibility = 'hidden'; 
            console.log(document.getElementsByClassName("map")[0].dataset.basemapid)

            // Build deficiency map when satellite basemap is chosen
            if (document.getElementsByClassName("map")[0].dataset.basemapid == "false"){
                document.getElementsByClassName("map")[0].id = Date.now()
                const map_container = document.getElementsByClassName("map")[0]
                const map = new mapboxgl.Map({
                    container: map_container.id, // container ID
                    style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
                    center: [map_container.dataset.longitude, map_container.dataset.latitude], // starting position [lng, lat]
                    zoom: 16, // starting zoom
                    preserveDrawingBuffer: true,
                    interactive: false,
                    dragPan: false
                });    

                const bounds = map.getBounds().toArray();
                var coordinatesCollection = [];
                
                map.on('load', function() {

                    jQuery.ajax({
                        url: document.getElementsByClassName("map")[0].dataset.otheractionitemsurl,
                        type: "GET",
                        dataType: 'JSON',
                        success: function (data, textStatus, jqXHR ){
                            //console.log(data);
                        },
                        complete: function ( data ) {
                            coordinatesCollection.push(bounds[0]);
                            coordinatesCollection.push(bounds[1]);

                            //console.log(data);

                            data.responseJSON.forEach( function(swpp_corrective_action){
                                // Check to see if anything was added to the map for this item
                                if(swpp_corrective_action.feature_string){
                                    var jsonObj = JSON.parse(swpp_corrective_action.feature_string)
                                } else {
                                    var jsonObj = false
                                }

                                if(jsonObj && converter(swpp_corrective_action.deficiency_map_id) == map_container.dataset.basemapid){
                                    jsonObj.features.forEach ( function(feature) {
                                        //console.log(feature.geometry)
                                        feature.properties.description = swpp_corrective_action.ref_id
                                        map.addSource(`${feature.id}`, {
                                            'type': 'geojson',
                                            'data': feature
                                        });
                                        // Add layer for drawn objects (polygons, points, etc)
                                        var type, paint_opts, layout_opts
                                        switch(feature.geometry.type) {
                                            case 'Polygon':
                                                map.addLayer({
                                                    'id': `${feature.id} fill`,
                                                    'type': 'fill',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'fill-color': oldColor,
                                                        'fill-outline-color': oldColor,
                                                        'fill-opacity': 0.1
                                                    }
                                                })
                                                map.addLayer({
                                                    'id': `${feature.id} outline`,
                                                    'type': 'line',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'line-color': oldColor,
                                                        'line-width': 2
                                                    }
                                                })
                                                break;
                                            case 'Point':
                                                map.addLayer({
                                                    'id': `${feature.id} circle`,
                                                    'type': 'circle',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'circle-color': oldColor,
                                                        'circle-radius': 5,
                                                    }
                                                })
                                                break;
                                            case 'LineString':
                                                map.addLayer({
                                                    'id': `${feature.id} line`,
                                                    'type': 'line',
                                                    'source': feature.id,
                                                    'layout': {
                                                        'line-cap': 'round',
                                                        'line-join': 'round'
                                                    },
                                                    'paint': {
                                                        'line-color': oldColor,
                                                        'line-width': 2
                                                    }
                                                })
                                                break;
                                            default:
                                        }
                                        // Add layer for Ref ID labels
                                        map.addLayer({
                                            'id': feature.id,
                                            'type': 'symbol',
                                            'source': `${feature.id}`,
                                            'layout': {
                                                'text-field': ['get', 'description'],
                                                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                                'text-radial-offset': 0.5,
                                                'text-justify': 'auto',
                                                'text-allow-overlap': false,
                                                'text-ignore-placement': false,
                                                'icon-allow-overlap': false,
                                                'icon-ignore-placement': false,
                                                'icon-image': ['get', 'icon']
                                            },
                                            'paint': {
                                                'text-color': oldColor,
                                                'text-halo-color': '#000000',
                                                'text-halo-width': 3
                                            }
                                        });

                                        if (!feature.geometry.coordinates[0][0]){
                                            //console.log("First condition")
                                            coordinatesCollection.push(feature.geometry.coordinates);
                                            //console.log(feature.geometry.coordinates);
                                        } else if(!feature.geometry.coordinates[0][0][0]) {
                                            //console.log("Second condition");
                                            for(let i = 0; i < feature.geometry.coordinates.length; i++){
                                                coordinatesCollection.push(feature.geometry.coordinates[i]);
                                                //console.log(feature.geometry.coordinates[i]);
                                            }
                                        } else {
                                            for(let i = 0; i < feature.geometry.coordinates.length; i++){
                                                for(let j = 0; j < feature.geometry.coordinates[i].length; j++){
                                                    coordinatesCollection.push(feature.geometry.coordinates[i][j]);
                                                    //console.log(feature.geometry.coordinates[i][j]);
                                                }
                                            }
                                        }
                                    })
                                }
                                                        
                            })
            
                            const lowestLng = Math.min(
                                ...coordinatesCollection.map((coordinates) => coordinates[0])
                            );
                            const lowestLat = Math.min(
                                ...coordinatesCollection.map((coordinates) => coordinates[1])
                            );
                            const highestLng = Math.max(
                                ...coordinatesCollection.map((coordinates) => coordinates[0])
                            );
                            const highestLat = Math.max(
                                ...coordinatesCollection.map((coordinates) => coordinates[1])
                            );

                            map.fitBounds([[lowestLng, lowestLat], [highestLng, highestLat]], {
                                padding: 30,
                                maxZoom: 18
                            });                  
                        }
            
                    })


                    jQuery.ajax({
                        url: document.getElementsByClassName("map")[0].dataset.url,
                        type: "GET",
                        dataType: 'JSON',
                        success: function (data, textStatus, jqXHR ){
                            //console.log(data);
                        },
                        complete: function ( data ) {
                            coordinatesCollection.push(bounds[0]);
                            coordinatesCollection.push(bounds[1]);

                            //console.log(data);

                            data.responseJSON.swpp_corrective_actions.forEach( function(swpp_corrective_action){


                                // Check to see if anything was added to the map for this item
                                if(swpp_corrective_action.feature_string){
                                    var jsonObj = JSON.parse(swpp_corrective_action.feature_string)
                                } else {
                                    var jsonObj = false
                                }
                                
                                //console.log(typeof(swpp_corrective_action.deficiency_map_id));
                                //console.log(typeof(map_container.dataset.basemapid))

                                if(jsonObj && converter(swpp_corrective_action.deficiency_map_id) == map_container.dataset.basemapid){
                                    jsonObj.features.forEach ( function(feature) {
                                        //console.log(feature.geometry)
                                        feature.properties.description = swpp_corrective_action.ref_id
                                        map.addSource(`${feature.id}`, {
                                            'type': 'geojson',
                                            'data': feature
                                        });
                                        // Add layer for drawn objects (polygons, points, etc)
                                        var type, paint_opts, layout_opts
                                        switch(feature.geometry.type) {
                                            case 'Polygon':
                                                map.addLayer({
                                                    'id': `${feature.id} fill`,
                                                    'type': 'fill',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'fill-color': newColor,
                                                        'fill-outline-color': newColor,
                                                        'fill-opacity': 0.1
                                                    }
                                                })
                                                //map.moveLayer('backgroundlayer', `${feature.id} fill`)
                                                //console.log(`${feature.id} fill`)
                                                map.addLayer({
                                                    'id': `${feature.id} outline`,
                                                    'type': 'line',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'line-color': newColor,
                                                        'line-width': 2
                                                    }
                                                })
                                                //map.moveLayer('backgroundlayer', `${feature.id} outline`)
                                                //console.log(`${feature.id} outline`)
                                                break;
                                            case 'Point':
                                                map.addLayer({
                                                    'id': `${feature.id} circle`,
                                                    'type': 'circle',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'circle-color': newColor,
                                                        'circle-radius': 5,
                                                    }
                                                })
                                                //map.moveLayer('backgroundlayer', `${feature.id} circle`)
                                                //console.log(`${feature.id} circle`)
                                                break;
                                            case 'LineString':
                                                map.addLayer({
                                                    'id': `${feature.id} line`,
                                                    'type': 'line',
                                                    'source': feature.id,
                                                    'layout': {
                                                        'line-cap': 'round',
                                                        'line-join': 'round'
                                                    },
                                                    'paint': {
                                                        'line-color': newColor,
                                                        'line-width': 2
                                                    }
                                                })
                                                //map.moveLayer('backgroundlayer', `${feature.id} line`)
                                                //console.log(`${feature.id} line`)
                                                break;
                                            default:
                                        }
                                        // Add layer for Ref ID labels
                                        map.addLayer({
                                            'id': feature.id,
                                            'type': 'symbol',
                                            'source': `${feature.id}`,
                                            'layout': {
                                                'text-field': ['get', 'description'],
                                                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                                'text-allow-overlap': false,
                                                'text-radial-offset': 0.5,
                                                'text-justify': 'auto',
                                                'text-allow-overlap': false,
                                                'text-ignore-placement': false,
                                                'icon-allow-overlap': false,
                                                'icon-ignore-placement': false,
                                                'icon-image': ['get', 'icon']
                                            },
                                            'paint': {
                                                'text-color': newColor,
                                                'text-halo-color': '#000000',
                                                'text-halo-width': 3
                                            }
                                        });
                                        //map.moveLayer(feature.id, 'backgroundlayer')
                                        //console.log(feature.id)
                
                                        if (!feature.geometry.coordinates[0][0]){
                                            //console.log("First condition")
                                            coordinatesCollection.push(feature.geometry.coordinates);
                                            //console.log(feature.geometry.coordinates);
                                        } else if(!feature.geometry.coordinates[0][0][0]) {
                                            //console.log("Second condition");
                                            for(let i = 0; i < feature.geometry.coordinates.length; i++){
                                                coordinatesCollection.push(feature.geometry.coordinates[i]);
                                                //console.log(feature.geometry.coordinates[i]);
                                            }
                                        } else {
                                            for(let i = 0; i < feature.geometry.coordinates.length; i++){
                                                for(let j = 0; j < feature.geometry.coordinates[i].length; j++){
                                                    coordinatesCollection.push(feature.geometry.coordinates[i][j]);
                                                    //console.log(feature.geometry.coordinates[i][j]);
                                                }
                                            }
                                        }
                                    })
        
                                }
                                                          
                            })
            
                            const lowestLng = Math.min(
                                ...coordinatesCollection.map((coordinates) => coordinates[0])
                            );
                            const lowestLat = Math.min(
                                ...coordinatesCollection.map((coordinates) => coordinates[1])
                            );
                            const highestLng = Math.max(
                                ...coordinatesCollection.map((coordinates) => coordinates[0])
                            );
                            const highestLat = Math.max(
                                ...coordinatesCollection.map((coordinates) => coordinates[1])
                            );

                            map.fitBounds([[lowestLng, lowestLat], [highestLng, highestLat]], {
                                padding: 30,
                                maxZoom: 18
                            });            
                        }
                    })

                })
                

                map.on('idle', function(){
                    jQuery.ajax({
                        xhr: function() {
                            var xhr = new window.XMLHttpRequest();
                            var progressBarImage = document.getElementById("image-upload-progress");
                            //Upload progress
                            xhr.upload.addEventListener("progress", function(evt){
                              if (evt.lengthComputable) {
                                //Do something with upload progress
                                var percentComplete = (evt.loaded/evt.total)*100;
                                percentComplete = Math.floor(percentComplete);
                                progressBarImage.style["width"] = percentComplete + "%";
                              }
                            }, false);
                            //Download progress
                            // xhr.addEventListener("progress", function(evt){
                            //   if (evt.lengthComputable) {
                            //     var percentComplete = evt.loaded / evt.total;
                            //     //Do something with download progress
                            //     console.log(percentComplete);
                            //   }
                            // }, false);
                            return xhr;
                        },
                        url: document.getElementsByClassName("map")[0].dataset.attachmapurl,
                        type: "PATCH",
                        dataType: "JSON",
                        data: {
                            swpp_inspection: {
                                deficiency_map: map.getCanvas().toDataURL("image/jpeg", imageRes)
                            }
                        },
                        success: function ( data, textStatus, jqXHR ) {
                            console.log("Success");
                            console.log(data);
                            console.log(textStatus);
                            console.log(jqXHR);
                            document.getElementById("finalize").style.visibility = 'visible'; 
                        },
                        error: function ( jqXHR, textStatus, error ) {
                            console.log("Error");
                            console.log(jqXHR);
                            console.log(textStatus);
                            console.log(error);
                        }
                    })
                })

                $("#finalize").click(function(e){
                    console.log("Click")
                })
            } else {
                // Build deficiency map if uploaded basemap is chosen

                var map2 = false;
                document.getElementById("map2").style.visibility = 'hidden'; 
                
                console.log("body.swpp_inspections.show and not sat map");
                //console.log(document.getElementsByClassName("map")[0].dataset.satelliteMap);
                //console.log(document.getElementsByClassName("map")[0].dataset.basemapurl);
                var i = 0;
                var j = 1;
                var layersCheck = "";
                var oldScaCount = -1;
                var k = 0;
                var newScaCount = -1;
                var l = 0;
                var lowestLat = 0;
                var lowestLng = 0;
                var highestLat = 0;
                var highestLng = 0;
                var height = 0;
                var width = 0;
                var dx = 0;
                var dy = 0;

                //console.log(document.getElementsByClassName("map")[0].dataset.satelliteMap)

                document.getElementsByClassName("map")[0].id = Date.now()
                const map_container = document.getElementsByClassName("map")[0]
                const map = new mapboxgl.Map({
                    container: map_container.id, // container ID
                    style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
                    center: [map_container.dataset.longitude, map_container.dataset.latitude], // starting position [lng, lat]
                    zoom: 16, // starting zoom
                    preserveDrawingBuffer: true,
                    interactive: false,
                    dragPan: false
                });   

                const center = [map_container.dataset.longitude, map_container.dataset.latitude];
                const map_container_sat = document.getElementById("map2")
                const map_sat = new mapboxgl.Map({
                    container: map_container_sat.id, // container ID
                    style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
                    center: [map_container_sat.dataset.longitude, map_container_sat.dataset.latitude], // starting position [lng, lat]
                    zoom: 16, // starting zoom
                    preserveDrawingBuffer: true,
                    interactive: false,
                    dragPan: false
                });    

                const bounds = map.getBounds().toArray();
                var coordinatesCollection = [];
                
                map.on('load', function() {    
                    const getMeta = (url, cb) => {
                        const img = new Image();
                        img.onload = () => cb(null, img);
                        img.onerror = (err) => cb(err);
                        img.src = url;
                    };

                    const mapPixelCenter = map.project(map.getCenter());
                    
                    getMeta(document.getElementsByClassName("map")[0].dataset.basemapurl + '?' + Math.random().toString().split('.').join(""), (err, img) => {
                        const lowestLatOld = Number(map_container.dataset.latitude) + img.naturalHeight/500000;
                        const lowestLngOld = Number(map_container.dataset.longitude) - img.naturalWidth/500000;
                        const highestLatOld = Number(map_container.dataset.latitude) - img.naturalHeight/500000;
                        const highestLngOld = Number(map_container.dataset.longitude) + img.naturalWidth/500000;

                        const lowestLatPixel = Number(mapPixelCenter.y) - Number(img.naturalHeight)/10;
                        const lowestLngPixel = Number(mapPixelCenter.x) - Number(img.naturalWidth)/10;
                        const highestLatPixel = Number(mapPixelCenter.y) + Number(img.naturalHeight)/10;
                        const highestLngPixel = Number(mapPixelCenter.x) + Number(img.naturalWidth)/10;

                        const lowestLatNew = Number(map.unproject([lowestLngPixel, lowestLatPixel]).lat);
                        const lowestLngNew = Number(map.unproject([lowestLngPixel, lowestLatPixel]).lng);
                        const highestLatNew = Number(map.unproject([highestLngPixel, highestLatPixel]).lat);
                        const highestLngNew = Number(map.unproject([highestLngPixel, highestLatPixel]).lng);

                        dx = (lowestLngNew - Number(map_container.dataset.longitude))/(lowestLngOld - Number(map_container.dataset.longitude))
                        dy = (lowestLatNew - Number(map_container.dataset.latitude))/(lowestLatOld - Number(map_container.dataset.latitude))

                        lowestLat = lowestLatNew;
                        lowestLng = lowestLngNew;
                        highestLat = highestLatNew;
                        highestLng = highestLngNew;
                        height = Number(img.naturalHeight);
                        width = Number(img.naturalWidth);
                    });

                    map.on('idle', function(){
                        if(i == 0 && highestLng == 0){
                            setTimeout(function(){
                                window.dispatchEvent(event);
                            }, 250)

                        }
                        if(i == 0 && highestLng != 0){
                            console.log("1. Loading image.")
                            map.loadImage(
                                document.getElementsByClassName("map")[0].dataset.basemapurl + '?' + Math.random().toString().split('.').join(""),
                                (error, image) => {
                                    if (error) throw error;
                
                                    map.addSource('radar', {
                                        'type': 'image',
                                        'url': map_container.dataset.basemapurl + '?' + Math.random().toString().split('.').join(""),
                                        'coordinates': [
                                                [lowestLng, lowestLat],
                                                [highestLng, lowestLat],
                                                [highestLng, highestLat],
                                                [lowestLng, highestLat]
                                            ]
                                        });
                                        map.addLayer({
                                            id: 'image',
                                            'type': 'raster',
                                            'source': 'radar',
                                            'paint': {
                                                'raster-fade-duration': 0
                                            }
                                        });
                                    
                                }
                            );  
                            window.dispatchEvent(event);
                            i = 1;
                        }
                        if(i == 1 && map.getLayer("image")){

                            console.log("Image loaded!")
                            console.log("2. Fitting map bounds.")

                            map.fitBounds([[Number(lowestLng), Number(lowestLat)], [Number(highestLng), Number(highestLat)]], {
                                padding: 0
                                //maxZoom: 18
                            });

                            i = 2;
                            window.dispatchEvent(event);
                        }
                        if(i == 2 && map.getLayer("image")){
                            console.log("3. Making unneeded layers invisible.")
                            
                            map.style.stylesheet.layers.forEach(function(layer) {
                                if(layer.id != "image"){
                                    map.setLayoutProperty(layer.id,"visibility", "none");
                                    j++;
                                    if(j == map.style.stylesheet.layers.length){
                                        layersCheck = "Done";
                                    }
                                } 
                            });

                            i = 3;
                            window.dispatchEvent(event);
                        }
                        if(i == 3 && layersCheck == "Done"){
                            console.log("4. Adding corrective actions from previous inspections.")
                            
                            jQuery.ajax({
                                url: document.getElementsByClassName("map")[0].dataset.otheractionitemsurl,
                                type: "GET",
                                dataType: 'JSON',
                                success: function (data, textStatus, jqXHR ){
                                    //console.log(data);
                                },
                                complete: function ( data ) {
                                    oldScaCount = data.responseJSON.length * 2;
                                    console.log(data);
                                    console.log(data.responseJSON);
                                    data.responseJSON.forEach( function(swpp_corrective_action){

                                        // Check to see if anything was added to the map for this item
                                        if(swpp_corrective_action.feature_string){
                                            var jsonObj = JSON.parse(swpp_corrective_action.feature_string)
                                        } else {
                                            var jsonObj = false
                                        }

                                        // Look for satellite map action items
                                        if(jsonObj && !swpp_corrective_action.deficiency_map_id){
                                            map2 = true;
                                            console.log("Satellite map action item(s) detected.")
                                        }
                                        // Add objects to map, if they exist
                                        if(jsonObj && converter(swpp_corrective_action.deficiency_map_id) == map_container.dataset.basemapid){
                                            if (swpp_corrective_action.old_coordinates){
                                                transformCoordinates(jsonObj, center, dx, dy);
                                                console.log("Value for old_coordinates:")
                                                console.log(swpp_corrective_action.old_coordinates);
                                            }
        
                                            jsonObj.features.forEach ( function(feature) {
                                                console.log(feature.geometry.coordinates)
                                                feature.properties.description = swpp_corrective_action.ref_id
                                                map.addSource(`${feature.id}`, {
                                                    'type': 'geojson',
                                                    'data': feature
                                                });
                                                // Add layer for drawn objects (polygons, points, etc)
                                                var type, paint_opts, layout_opts
                                                switch(feature.geometry.type) {
                                                    case 'Polygon':
                                                        map.addLayer({
                                                            'id': `${feature.id} fill`,
                                                            'type': 'fill',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'fill-color': oldColor,
                                                                'fill-outline-color': oldColor,
                                                                'fill-opacity': 0.1
                                                            }
                                                        })
                                                        map.addLayer({
                                                            'id': `${feature.id} outline`,
                                                            'type': 'line',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'line-color': oldColor,
                                                                'line-width': 2
                                                            }
                                                        })
                                                        layerId = `${feature.id} fill`;
                                                        k++;
                                                        break;
                                                    case 'Point':
                                                        map.addLayer({
                                                            'id': `${feature.id} circle`,
                                                            'type': 'circle',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'circle-color': oldColor,
                                                                'circle-radius': 5,
                                                            }
                                                        })
                                                        layerId = `${feature.id} circle`;
                                                        k++
                                                        break;
                                                    case 'LineString':
                                                        map.addLayer({
                                                            'id': `${feature.id} line`,
                                                            'type': 'line',
                                                            'source': feature.id,
                                                            'layout': {
                                                                'line-cap': 'round',
                                                                'line-join': 'round'
                                                            },
                                                            'paint': {
                                                                'line-color': oldColor,
                                                                'line-width': 2
                                                            }
                                                        })
                                                        layerId = `${feature.id} line`;
                                                        k++
                                                        break;
                                                    default:
                                                }
                                                
                                                // Add layer for Ref ID labels
                                                map.addLayer({
                                                    'id': feature.id,
                                                    'type': 'symbol',
                                                    'source': `${feature.id}`,
                                                    'layout': {
                                                        'text-field': ['get', 'description'],
                                                        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                                        'text-radial-offset': 0.5,
                                                        'text-justify': 'auto',
                                                        'text-allow-overlap': false,
                                                        'text-ignore-placement': false,
                                                        'icon-allow-overlap': false,
                                                        'icon-ignore-placement': false,
                                                        'icon-image': ['get', 'uploadmap']
                                                    },
                                                    'paint': {
                                                        'text-color': oldColor,
                                                        'text-halo-color': '#000000',
                                                        'text-halo-width': 3
                                                    }
                                                });
                                                k++
            
                                                
                                            })
                                        }
                                                                
                                    })
                                                    
                                }
                    
                            })

                            i = 4;
                            window.dispatchEvent(event);

                        }
                        if(i == 4){
                            console.log("5. Adding corrective actions from current inspection.")
                            
                            jQuery.ajax({
                                url: document.getElementsByClassName("map")[0].dataset.url,
                                type: "GET",
                                dataType: 'JSON',
                                success: function (data, textStatus, jqXHR ){
                                    //console.log(data);
                                },
                                complete: function ( data ) {
            
                                    newScaCount = data.responseJSON.swpp_corrective_actions.length * 2;
                                    
                                    data.responseJSON.swpp_corrective_actions.forEach( function(swpp_corrective_action){
                                        // Check to see if anything was added to the map for this item
                                        if(swpp_corrective_action.feature_string){
                                            var jsonObj = JSON.parse(swpp_corrective_action.feature_string)
                                        } else {
                                            var jsonObj = false
                                        }

                                        if(jsonObj && converter(swpp_corrective_action.deficiency_map_id) == map_container.dataset.basemapid){
                                            // It is never necessary to transform coordinates for new action items
                                            if (false){
                                                transformCoordinates(jsonObj, center, map, width, height);
                                                console.log("Value for old_coordinates:")
                                                console.log(swpp_corrective_action.old_coordinates);
                                            }

                                            jsonObj.features.forEach ( function(feature) {
                                                //console.log(feature.geometry)
                                                feature.properties.description = swpp_corrective_action.ref_id
                                                map.addSource(`${feature.id}`, {
                                                    'type': 'geojson',
                                                    'data': feature
                                                });
                                                console.log(feature.geometry.coordinates)
                                                // Add layer for drawn objects (polygons, points, etc)
                                                var type, paint_opts, layout_opts
                                                switch(feature.geometry.type) {
                                                    case 'Polygon':
                                                        map.addLayer({
                                                            'id': `${feature.id} fill`,
                                                            'type': 'fill',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'fill-color': newColor,
                                                                'fill-outline-color': newColor,
                                                                'fill-opacity': 0.1
                                                            },
                                                        })
                                                        map.addLayer({
                                                            'id': `${feature.id} outline`,
                                                            'type': 'line',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'line-color': newColor,
                                                                'line-width': 2
                                                            }
                                                        })
                                                        //map.moveLayer('backgroundlayer', `${feature.id} outline`)
                                                        layerId = `${feature.id} fill`;
                                                        l++
                                                        break;
                                                    case 'Point':
                                                        map.addLayer({
                                                            'id': `${feature.id} circle`,
                                                            'type': 'circle',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'circle-color': newColor,
                                                                'circle-radius': 5
                                                            }
                                                        })
                                                        l++;
                                                        layerId = `${feature.id} circle`;
                                                        break;
                                                    case 'LineString':
                                                        map.addLayer({
                                                            'id': `${feature.id} line`,
                                                            'type': 'line',
                                                            'source': feature.id,
                                                            'background-color': 'transparent',
                                                            'background-opacity': 0,
                                                            'layout': {
                                                                'line-cap': 'round',
                                                                'line-join': 'round'
                                                            },
                                                            'paint': {
                                                                'line-color': newColor,
                                                                'line-width': 2
                                                            }
                                                        })
                                                        l++;
                                                        layerId = `${feature.id} line`;
                                                        break;
                                                    default:
                                                }
                                                // Add layer for Ref ID labels
                                                map.addLayer({
                                                    'id': feature.id,
                                                    'type': 'symbol',
                                                    'source': `${feature.id}`,
                                                    'layout': {
                                                        'text-field': ['get', 'description'],
                                                        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                                        'text-radial-offset': 0.5,
                                                        'text-justify': 'auto',
                                                        'text-allow-overlap': false,
                                                        'text-ignore-placement': false,
                                                        'icon-allow-overlap': false,
                                                        'icon-ignore-placement': false,
                                                        'icon-image': ['get', 'uploadmap']
                                                    },
                                                    'paint': {
                                                        'text-color': newColor,
                                                        'text-halo-color': '#000000',
                                                        'text-halo-width': 3
                                                    }
                                                });
                                                l++;
                                            })  
                                        }                        
                                    })         
                                }
                            })

                            i = 5;

                            window.dispatchEvent(event);     
                        }
                        if(i == 5){
                            setTimeout(function(){
                                i = 6;
                                window.dispatchEvent(event);  
                                //document.getElementById("finalize").style.visibility = 'visible'; 
                            }, 200)
                        }
                        if(i == 6){
                            setTimeout(function(){
                                console.log("6. Uploading map.")
                                i = 7;
                                uploadMap(map);
                                //document.getElementById("finalize").style.visibility = 'visible'; 
                            }, 1000)
                        }
                    })

                })
                
                        
                // var swpp_inspection_id = map_container.dataset.inspection


                $("#finalize").click(function(e){
                    
            
                })

                $("#generate").click(function(e){
                    console.log("Click!")
                    document.getElementById("finalize").style.visibility = 'hidden'; 
                    document.getElementById("generate").style.visibility = 'hidden'; 
                    document.getElementById("map2").style.visibility = 'visible'; 

                    const bounds = map_sat.getBounds().toArray();
                    var coordinatesCollection = [];
                    

                    jQuery.ajax({
                        url: document.getElementById("map2").dataset.otheractionitemsurl,
                        type: "GET",
                        dataType: 'JSON',
                        success: function (data, textStatus, jqXHR ){
                            //console.log(data);
                        },
                        complete: function ( data ) {
                            coordinatesCollection.push(bounds[0]);
                            coordinatesCollection.push(bounds[1]);

                            data.responseJSON.forEach( function(swpp_corrective_action){
                                // Check to see if anything was added to the map for this item
                                if(swpp_corrective_action.feature_string){
                                    var jsonObj = JSON.parse(swpp_corrective_action.feature_string)
                                } else {
                                    var jsonObj = false
                                }


                                if(jsonObj && !swpp_corrective_action.deficiency_map_id){
                                    jsonObj.features.forEach ( function(feature) {
                                        //console.log(feature.geometry)
                                        feature.properties.description = swpp_corrective_action.ref_id
                                        map_sat.addSource(`${feature.id}`, {
                                            'type': 'geojson',
                                            'data': feature
                                        });
                                        // Add layer for drawn objects (polygons, points, etc)
                                        var type, paint_opts, layout_opts
                                        switch(feature.geometry.type) {
                                            case 'Polygon':
                                                map_sat.addLayer({
                                                    'id': `${feature.id} fill`,
                                                    'type': 'fill',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'fill-color': oldColor,
                                                        'fill-outline-color': oldColor,
                                                        'fill-opacity': 0.1
                                                    }
                                                })
                                                map_sat.addLayer({
                                                    'id': `${feature.id} outline`,
                                                    'type': 'line',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'line-color': oldColor,
                                                        'line-width': 2
                                                    }
                                                })
                                                break;
                                            case 'Point':
                                                map_sat.addLayer({
                                                    'id': `${feature.id} circle`,
                                                    'type': 'circle',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'circle-color': oldColor,
                                                        'circle-radius': 5,
                                                    }
                                                })
                                                break;
                                            case 'LineString':
                                                map_sat.addLayer({
                                                    'id': `${feature.id} line`,
                                                    'type': 'line',
                                                    'source': feature.id,
                                                    'layout': {
                                                        'line-cap': 'round',
                                                        'line-join': 'round'
                                                    },
                                                    'paint': {
                                                        'line-color': oldColor,
                                                        'line-width': 2
                                                    }
                                                })
                                                break;
                                            default:
                                        }
                                        // Add layer for Ref ID labels
                                        map_sat.addLayer({
                                            'id': feature.id,
                                            'type': 'symbol',
                                            'source': `${feature.id}`,
                                            'layout': {
                                                'text-field': ['get', 'description'],
                                                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                                'text-radial-offset': 0.5,
                                                'text-justify': 'auto',
                                                'text-allow-overlap': false,
                                                'text-ignore-placement': false,
                                                'icon-allow-overlap': false,
                                                'icon-ignore-placement': false,
                                                'icon-image': ['get', 'icon']
                                            },
                                            'paint': {
                                                'text-color': oldColor,
                                                'text-halo-color': '#000000',
                                                'text-halo-width': 3
                                            }
                                        });

                                        if (!feature.geometry.coordinates[0][0]){
                                            //console.log("First condition")
                                            coordinatesCollection.push(feature.geometry.coordinates);
                                            //console.log(feature.geometry.coordinates);
                                        } else if(!feature.geometry.coordinates[0][0][0]) {
                                            //console.log("Second condition");
                                            for(let i = 0; i < feature.geometry.coordinates.length; i++){
                                                coordinatesCollection.push(feature.geometry.coordinates[i]);
                                                //console.log(feature.geometry.coordinates[i]);
                                            }
                                        } else {
                                            for(let i = 0; i < feature.geometry.coordinates.length; i++){
                                                for(let j = 0; j < feature.geometry.coordinates[i].length; j++){
                                                    coordinatesCollection.push(feature.geometry.coordinates[i][j]);
                                                    //console.log(feature.geometry.coordinates[i][j]);
                                                }
                                            }
                                        }
                                    })
                                }
                                                        
                            })
            
                            const lowestLng = Math.min(
                                ...coordinatesCollection.map((coordinates) => coordinates[0])
                            );
                            const lowestLat = Math.min(
                                ...coordinatesCollection.map((coordinates) => coordinates[1])
                            );
                            const highestLng = Math.max(
                                ...coordinatesCollection.map((coordinates) => coordinates[0])
                            );
                            const highestLat = Math.max(
                                ...coordinatesCollection.map((coordinates) => coordinates[1])
                            );

                            map_sat.fitBounds([[lowestLng, lowestLat], [highestLng, highestLat]], {
                                padding: 30,
                                maxZoom: 18
                            });                   
                        }
                    })

                    map_sat.on('idle', function(){
                        uploadMapTwo(map_sat)
                    })
                })

            }

            function converter(val){
                if(val == null){
                    return "false"
                } else {
                    return val
                }
            }

            function uploadMap(map){
                console.log("URL:" + document.getElementsByClassName("map")[0].dataset.attachmapurl)
                jQuery.ajax({
                    xhr: function() {
                        var xhr = new window.XMLHttpRequest();
                        var progressBarImage = document.getElementById("image-upload-progress");
                        //Upload progress
                        xhr.upload.addEventListener("progress", function(evt){
                          if (evt.lengthComputable) {
                            //Do something with upload progress
                            var percentComplete = (evt.loaded/evt.total)*100;
                            percentComplete = Math.floor(percentComplete);
                            progressBarImage.style["width"] = percentComplete + "%";
                          }
                        }, false);
                        //Download progress
                        // xhr.addEventListener("progress", function(evt){
                        //   if (evt.lengthComputable) {
                        //     var percentComplete = evt.loaded / evt.total;
                        //     //Do something with download progress
                        //     console.log(percentComplete);
                        //   }
                        // }, false);
                        return xhr;
                    },
                    url: document.getElementsByClassName("map")[0].dataset.attachmapurl,
                    type: "PATCH",
                    dataType: "JSON",
                    data: {
                        swpp_inspection: {
                            deficiency_map: map.getCanvas().toDataURL("image/jpeg", imageRes)
                        }
                    },
                    success: function ( data, textStatus, jqXHR ) {
                        console.log("Success");
                        console.log(data);
                        console.log(textStatus);
                        console.log(jqXHR);
                        document.getElementById("finalize").style.visibility = 'visible'; 
                    },
                    error: function ( jqXHR, textStatus, error ) {
                        console.log("Error");
                        console.log(jqXHR);
                        console.log(textStatus);
                        console.log(error);
                    }
                })
            }

            function uploadMapTwo(map_sat){                  
                jQuery.ajax({
                    url: document.getElementById("map2").dataset.attachsatellitemapurl,
                    type: "PATCH",
                    dataType: "JSON",
                    data: {
                        swpp_inspection: {
                            deficiency_satellite_map: map_sat.getCanvas().toDataURL("image/jpeg", imageRes)
                        }
                    },
                    success: function ( data, textStatus, jqXHR ) {
                        console.log("Success");
                        console.log(data);
                        console.log(textStatus);
                        console.log(jqXHR);
                        document.getElementById("finalize").style.visibility = 'visible'; 
                    },
                    error: function ( jqXHR, textStatus, error ) {
                        console.log("Error");
                        console.log(jqXHR);
                        console.log(textStatus);
                        console.log(error);
                    }
                })
                
            }

            function convertPoint(point, center, dx, dy) {
                var newPoint = [];

                newPoint[0] = (point[0] - Number(center[0]))*dx + Number(center[0]);
                newPoint[1] = (point[1] - Number(center[1]))*dy + Number(center[1]);
                return newPoint;
            }
    
            function transformCoordinates(jsonObj, center, dx, dy){
                console.log("Features:")
                jsonObj.features.forEach(function(feature){
                    if (!feature.geometry.coordinates[0][0]){
                        var point = feature.geometry.coordinates
                        feature.geometry.coordinates = convertPoint(point, center, dx, dy);
                    } else if(!feature.geometry.coordinates[0][0][0]) {
                        //console.log("Second condition");
                        for(let i = 0; i < feature.geometry.coordinates.length; i++){
                            var point = feature.geometry.coordinates[i];
                            feature.geometry.coordinates[i] = convertPoint(point, center, dx, dy);
                        }
                    } else {
                        //console.log("Third condition")
                        for(let i = 0; i < feature.geometry.coordinates.length; i++){
                            for(let j = 0; j < feature.geometry.coordinates[i].length; j++){
                                var point = feature.geometry.coordinates[i][j];
                                feature.geometry.coordinates[i][j] = convertPoint(point, center, dx, dy);
                            }
                        }
                    }
                    
        
                })
    
            }

            
        }

        function updateFormBeforeSubmit(event) {
            console.log("Hello!!!!")  
        };
        

    }

    if ( document.querySelector('body.swpp_inspections.edit') ) {
        console.log("edit")

    }
})