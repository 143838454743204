$(document).on("turbolinks:load", function () {
  
  const elements = document.querySelectorAll('.datepicker');
  for (const element of elements) {
    $(element).datepicker({
      format: "yyyy-mm-dd",
      todayBtn: "linked",
      autoclose: true,
      todayHighlight: true
    });
  }

});
