// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

window.Rails = Rails;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('src/datatables_init')
require('src/signature')
require('src/users')
require('src/products')
require('src/custom')
require('src/map_revision')
require('src/map_editor')
require('src/map_show_static_canvas')
require('src/nested_fields')
require('src/template_inspection_reports')
require('src/companies')
require('src/proposals')
require('src/sites')
require('src/lot_stabilization_log_entries')
require('src/swpp_image_attachments')
require('src/maps')
require('src/bulk_download')
require('src/dashboards')
require('src/reports')
require('src/work_orders')
require('src/rain_events')
require('src/direct_uploads')
require('src/user_notifications')
require('src/grading_logs')
require('src/legend_items')
require('src/features')
require('src/data-confirm-modal')
require('src/ckeditor_init')
require('src/swpp_corrective_actions')
require('src/swpp_inspections')
require('src/mapbox')
require('src/datepicker')