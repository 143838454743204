$(document).on("turbolinks:load", function () {

    if ( document.querySelector('body.proposals.show') ) {
        let summaryEditor
        let coverEditor
    
        ClassicEditor
            .create( document.querySelector( '#proposal_summary_content' ), {
                removePlugins: [ 'Heading' ],
                toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' , 'link' ]
            })
            // .then(editor => {
            //     window.editor = editor;
            //     summaryEditor = editor;
            //     summaryEditor.setData("<p>TEST</p>");
            //     console.log(summaryEditor)
            // })
            .catch( error => {
                console.error( error );
        } );
    
        ClassicEditor
            .create( document.querySelector( '#proposal_cover_page_content' ), {
                removePlugins: [ 'Heading' ],
                toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' , 'link' ]
            })
            // .then(editor => {
            //     window.editor = editor;
            //     coverEditor = editor;
            //     coverEditor.setData("<p><b>T</b>EST</p>");
            //     console.log(coverEditor)
            // })
            .catch( error => {
                console.error( error );
        } );
    
    }
});