$(document).on('turbolinks:load', function() {

	if ( document.querySelector('body.bulk_downloads.index') ) {

		// https://stackoverflow.com/questions/36217098/checkboxes-only-work-on-first-page-datatables-rails
		var table = $('#dtBulkDownloadIndex').DataTable({
			processing: true,
	    serverSide: true,
	    ajax: {
	      url: $('#dtBulkDownloadIndex').data('source'),
	      type: 'POST'
	    },
	    pagingType: "full_numbers",
	    columns: [
				{data: "id"},
	      {data: "name"},
	      {data: "state"},
	    ],
			columnDefs: [
				{
					targets: 0,
					checkboxes: {
						selectRow: true
					}
				}
			],
			select: {
				style: "multi"
			},
			order: [
				[1, "asc"]
			]
	    // pagingType is optional, if you want full pagination controls.
	    // Check dataTables documentation to learn more about
	    // available options.
		});


		// Handle form submission event
		$('form').on('submit', function(e){
		  var form = this;

		  var rows_selected = table.column(0).checkboxes.selected();

		  // Iterate over all selected checkboxes
		  $.each(rows_selected, function(index, rowId){
		     // Create a hidden element
		     $(form).append(
		         $('<input>')
		            .attr('type', 'hidden')
		            .attr('name', 'id[]')
		            .val(rowId)
		     );
		  });
		});

	};

});
