import bbox from '@turf/bbox'

$(document).on("turbolinks:load", function() {
  if ( document.querySelector('body.swpp_inspections') ) {
    const newColor = "#FFFF00"

    $('.subquestion-fields').each(function (){
      parent = $(this.parentNode);
      const regex = /^([^.]+)/;
      const label = parent.find("input[id*=title]")[0].value.match(regex)[0];
      $(this).find('.subquestion-fields .nested-fields:visible').each(function ( index ){
        if ($(this).find('.ref-id').value == undefined) {
          $(this).find('.ref-id').val(label + '.' + (index + 1));
        }
      });
    });
    
    document.getElementsByClassName('action-item').forEach(function (element){
        const map_container = element.getElementsByClassName("map")[0]
        var i = 0;
        
        // For editing existing action items. 
        // If an item was previously added to the map for this deficiency, retrieve corresponding JSON object
        if($(map_container.parentNode).find("input[id*=feature_string]").val()){
            var oldCoordinates = $(map_container.parentNode).find("input[id*=old_coordinates]").val()
            console.log("OLD COORDS?")
            console.log(oldCoordinates)
            var jsonObj = JSON.parse( $(map_container.parentNode).find("input[id*=feature_string]").val() )
        }
        
        $(map_container.parentNode).find("input[id*=deficiency_map_id]").val(document.getElementById("deficiencyMapId").dataset.basemapId)
        
        // Initialze deficiency map
        const map = new mapboxgl.Map({
            container: map_container.id, // container ID
            style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
            center: [map_container.dataset.longitude, map_container.dataset.latitude], // starting position [lng, lat]
            zoom: 16, // starting zoom
            preserveDrawingBuffer: true,
        });      

        map.on('load', function() {

            // Check for basemap id; if it exists, construct appropriate map in editor, and if not, move to else block below
            if(!document.getElementById("deficiencyMapId").dataset.basemapId == false){ 
                
                var lowestLat = 0;
                var lowestLng = 0;
                var highestLat = 0;
                var highestLng = 0;
                var dx = 0;
                var dy = 0;

                const getMeta = (url, cb) => {
                    const img = new Image();
                    img.onload = () => cb(null, img);
                    img.onerror = (err) => cb(err);
                    img.src = url;
                };

                const mapPixelCenter = map.project(map.getCenter());
                    
                getMeta(map_container.dataset.basemapurl + '?' + Math.random().toString().split('.').join(""), (err, img) => {
                    const lowestLatOld = Number(map_container.dataset.latitude) + img.naturalHeight/500000;
                    const lowestLngOld = Number(map_container.dataset.longitude) - img.naturalWidth/500000;
                    const highestLatOld = Number(map_container.dataset.latitude) - img.naturalHeight/500000;
                    const highestLngOld = Number(map_container.dataset.longitude) + img.naturalWidth/500000;

                    const lowestLatPixel = Number(mapPixelCenter.y) - Number(img.naturalHeight)/10;
                    const lowestLngPixel = Number(mapPixelCenter.x) - Number(img.naturalWidth)/10;
                    const highestLatPixel = Number(mapPixelCenter.y) + Number(img.naturalHeight)/10;
                    const highestLngPixel = Number(mapPixelCenter.x) + Number(img.naturalWidth)/10;

                    const lowestLatNew = Number(map.unproject([lowestLngPixel, lowestLatPixel]).lat);
                    const lowestLngNew = Number(map.unproject([lowestLngPixel, lowestLatPixel]).lng);
                    const highestLatNew = Number(map.unproject([highestLngPixel, highestLatPixel]).lat);
                    const highestLngNew = Number(map.unproject([highestLngPixel, highestLatPixel]).lng);

                    dx = (lowestLngNew - Number(map_container.dataset.longitude))/(lowestLngOld - Number(map_container.dataset.longitude))
                    dy = (lowestLatNew - Number(map_container.dataset.latitude))/(lowestLatOld - Number(map_container.dataset.latitude))

                    lowestLat = lowestLatNew;
                    lowestLng = lowestLngNew;
                    highestLat = highestLatNew;
                    highestLng = highestLngNew;
                    
                    const event = new Event('resize');
                    window.dispatchEvent(event);
                });
                
                map.on('idle', function() {
                    if(i == 0 && highestLng == 0){
                        setTimeout(function(){
                            const event = new Event('resize');
                            window.dispatchEvent(event);
                        }, 250)

                    }
                    if(i == 0 && highestLng != 0){
                        console.log("1. Adding image.")
                        
                        map.addSource('basemap', {
                            'type': 'image',
                            'url': map_container.dataset.basemapurl  + '?' + Math.random().toString().split('.').join(""),
                            'coordinates': [
                                [lowestLng, lowestLat],
                                [highestLng, lowestLat],
                                [highestLng, highestLat],
                                [lowestLng, highestLat]
                            ]
                        });
                        map.addLayer({
                            id: 'image',
                            'type': 'raster',
                            'source': 'basemap',
                            'paint': {
                                'raster-fade-duration': 0
                            }
                        });

                        //console.log(document.getElementsByClassName("map")[0].dataset.basemapurl)
                        const event = new Event('resize');
                        window.dispatchEvent(event);
                        i = 1;
                    }
                    if(i == 1 && !map.getLayer("image")){
                        setTimeout(function(){
                            const event = new Event('resize');
                            window.dispatchEvent(event);
                        }, 250)

                    }
                    if(i == 1 && map.getLayer("image")){

                        console.log("Image added!")
                        console.log("2. Fitting map bounds.")

                        map.fitBounds([[Number(lowestLng), Number(lowestLat)], [Number(highestLng), Number(highestLat)]], {
                            padding: 0
                            //maxZoom: 18
                        });

                        i = 2;

                        const event = new Event('resize');
                        window.dispatchEvent(event);
                    }
                    if(i == 2 && map.getLayer("image")){
                        console.log("3. Making unneeded layers invisible.")
                        
                        map.style.stylesheet.layers.forEach(function(layer) {
                            if((layer.id != "image" && layer.source != "mapbox-gl-draw-hot") && layer.source != "mapbox-gl-draw-cold"){
                                map.setLayoutProperty(layer.id,"visibility", "none");
                                //console.log(layer.layout.visibility)
                                //console.log(layer)   
                            } 
                        });

                        i = 3;

                        const event = new Event('resize');
                        window.dispatchEvent(event);
                    }
                    if (i == 3 && map.getLayer("gl-draw-polygon-fill-inactive.cold") && map.getLayer("image")){
                        console.log("3. Interchanging map layers.")
                        map.moveLayer("image","gl-draw-polygon-fill-inactive.cold")
                        console.log("LAYERS AND OTHER MAP DATA (before drag):");
                        console.log(map.getStyle().layers);
                        console.log(map)
                        //var layer = map.getLayer("points");
                        //console.log(layer)
                        i = 4;
                    }
                    if (i == 4){
                        console.log("4. Resize map.")
                        //map.resize()
                        i = 5;
                    }
                });
                
                // Add draw controls and styles, no matter what type of map

                map.addControl(new mapboxgl.NavigationControl());
                
                var draw = new MapboxDraw({
                    controls: {
                        combine_features: false,
                        uncombine_features: false
                    },
                    styles: [

            
            
                        {
                            'id': 'gl-draw-polygon-fill-inactive',
                            'type': 'fill',
                            'filter': ['all', ['==', 'active', 'false'],
                                ['==', '$type', 'Polygon'],
                                ['!=', 'mode', 'static']
                            ],
                            'paint': {
                                'fill-color': newColor,
                                'fill-outline-color': newColor,
                                'fill-opacity': 0.1
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-fill-active',
                            'type': 'fill',
                            'filter': ['all', ['==', 'active', 'true'],
                                ['==', '$type', 'Polygon']
                            ],
                            'paint': {
                                'fill-color': newColor,
                                'fill-outline-color': newColor,
                                'fill-opacity': 0.1
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-midpoint',
                            'type': 'circle',
                            'filter': ['all', ['==', '$type', 'Point'],
                                ['==', 'meta', 'midpoint']
                            ],
                            'paint': {
                                'circle-radius': 3,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-stroke-inactive',
                            'type': 'line',
                            'filter': ['all', ['==', 'active', 'false'],
                                ['==', '$type', 'Polygon'],
                                ['!=', 'mode', 'static']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-stroke-active',
                            'type': 'line',
                            'filter': ['all', ['==', 'active', 'true'],
                                ['==', '$type', 'Polygon']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-dasharray': [0.2, 2],
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-line-inactive',
                            'type': 'line',
                            'filter': ['all', ['==', 'active', 'false'],
                                ['==', '$type', 'LineString'],
                                ['!=', 'mode', 'static']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-line-active',
                            'type': 'line',
                            'filter': ['all', ['==', '$type', 'LineString'],
                                ['==', 'active', 'true']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-dasharray': [0.2, 2],
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
                            'type': 'circle',
                            'filter': ['all', ['==', 'meta', 'vertex'],
                                ['==', '$type', 'Point'],
                                ['!=', 'mode', 'static']
                            ],
                            'paint': {
                                'circle-radius': 5,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-and-line-vertex-inactive',
                            'type': 'circle',
                            'filter': ['all', ['==', 'meta', 'vertex'],
                                ['==', '$type', 'Point'],
                                ['!=', 'mode', 'static']
                            ],
                            'paint': {
                                'circle-radius': 3,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-point-point-stroke-inactive',
                            'type': 'circle',
                            'filter': ['all', ['==', 'active', 'false'],
                                ['==', '$type', 'Point'],
                                ['==', 'meta', 'feature'],
                                ['!=', 'mode', 'static']
                            ],
                            'paint': {
                                'circle-radius': 5,
                                'circle-opacity': 1,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-point-inactive',
                            'type': 'circle',
                            'filter': ['all', ['==', 'active', 'false'],
                                ['==', '$type', 'Point'],
                                ['==', 'meta', 'feature'],
                                ['!=', 'mode', 'static']
                            ],
                            'paint': {
                                'circle-radius': 3,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-point-stroke-active',
                            'type': 'circle',
                            'filter': ['all', ['==', '$type', 'Point'],
                                ['==', 'active', 'true'],
                                ['!=', 'meta', 'midpoint']
                            ],
                            'paint': {
                                'circle-radius': 7,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-point-active',
                            'type': 'circle',
                            'filter': ['all', ['==', '$type', 'Point'],
                                ['!=', 'meta', 'midpoint'],
                                ['==', 'active', 'true']
                            ],
                            'paint': {
                                'circle-radius': 5,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-fill-static',
                            'type': 'fill',
                            'filter': ['all', ['==', 'mode', 'static'],
                                ['==', '$type', 'Polygon']
                            ],
                            'paint': {
                                'fill-color': newColor,
                                'fill-outline-color': newColor,
                                'fill-opacity': 0.1
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-stroke-static',
                            'type': 'line',
                            'filter': ['all', ['==', 'mode', 'static'],
                                ['==', '$type', 'Polygon']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-line-static',
                            'type': 'line',
                            'filter': ['all', ['==', 'mode', 'static'],
                                ['==', '$type', 'LineString']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-point-static',
                            'type': 'circle',
                            'filter': ['all', ['==', 'mode', 'static'],
                                ['==', '$type', 'Point']
                            ],
                            'paint': {
                                'circle-radius': 5,
                                'circle-color': newColor
                            }
                        },
            
        
            
                        {
                            'id': 'gl-draw-polygon-color-picker',
                            'type': 'fill',
                            'filter': ['all', ['==', '$type', 'Polygon'],
                                ['has', 'user_portColor']
                            ],
                            'paint': {
                                'fill-color': ['get', 'user_portColor'],
                                'fill-outline-color': ['get', 'user_portColor'],
                                'fill-opacity': 0.5
                            }
                        },
                        {
                            'id': 'gl-draw-line-color-picker',
                            'type': 'line',
                            'filter': ['all', ['==', '$type', 'LineString'],
                                ['has', 'user_portColor']
                            ],
                            'paint': {
                                'line-color': ['get', 'user_portColor'],
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-point-color-picker',
                            'type': 'circle',
                            'filter': ['all', ['==', '$type', 'Point'],
                                ['has', 'user_portColor']
                            ],
                            'paint': {
                                'circle-radius': 3,
                                'circle-color': ['get', 'user_portColor']
                            }
                        },
            
                    ]
                });
                map.addControl(draw, 'top-left');

                        
                // If an item was previously added to the map for this deficiency, add it back now
                if($(map_container.parentNode).find("input[id*=feature_string]").val()){
                    draw.add(jsonObj)
                }
        
                var drawFeatureID;
        
                var setDrawFeature = function(e) {
                if (e.features.length && e.features[0].type === 'Feature') {
                    var feat = e.features[0];
                    drawFeatureID = feat.id;
                }
                }
        
                // Add zoom controls
                //map.addControl(new mapboxgl.NavigationControl());
        
                map.on('draw.create', function (e) {

                    setDrawFeature(e);
                    draw.setFeatureProperty(drawFeatureID, 'portColor', newColor);
        
                    var data = draw.getAll();
                    $(e.target._container.parentNode).find("input[id*=feature_string]").val(JSON.stringify(data));
                    var a = $(e.target._container.parentNode).find("input[id*=old_coordinates]").val();
                    console.log("AAAAAA")
                    console.log(a);
                    // $(e.target._container.parentNode).find("input[id*=old_coordinates]").val(false);
                    console.log(a);
                    var mapData = map.getCanvas().toDataURL();
                    // console.log(mapData);
                    $(e.target._container.parentNode).find("input[id*=map_image]").val(JSON.stringify(mapData));
                });
        
                map.on('draw.delete', function (e) {
                    var data = draw.getAll();
                    $(e.target._container.parentNode).find("input[id*=feature_string]").val(JSON.stringify(data));
                    var mapData = map.getCanvas().toDataURL();
                    $(e.target._container.parentNode).find("input[id*=map_image]").val(JSON.stringify(mapData));
                });
        
                map.on('draw.update', function (e) {
                    setDrawFeature(e);
                    draw.setFeatureProperty(drawFeatureID, 'portColor', newColor);
                    var data = draw.getAll();
                    $(e.target._container.parentNode).find("input[id*=feature_string]").val(JSON.stringify(data));
                    var mapData = map.getCanvas().toDataURL();
                    $(e.target._container.parentNode).find("input[id*=map_image]").val(JSON.stringify(mapData));
                });
                

                map.on('draw.load', () => {
                    console.log('A style load event occurred.');
                });

                    
            } else {
                // If basemap id does not exist, construct editor with satellite map
                var draw = new MapboxDraw({
                    controls: {
                        combine_features: false,
                        uncombine_features: false
                    },
                    styles: [

            
                        {
                            'id': 'gl-draw-polygon-fill-inactive',
                            'type': 'fill',
                            'filter': ['all', ['==', 'active', 'false'],
                                ['==', '$type', 'Polygon'],
                                ['!=', 'mode', 'static']
                            ],
                            'paint': {
                                'fill-color': newColor,
                                'fill-outline-color': newColor,
                                'fill-opacity': 0.1
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-fill-active',
                            'type': 'fill',
                            'filter': ['all', ['==', 'active', 'true'],
                                ['==', '$type', 'Polygon']
                            ],
                            'paint': {
                                'fill-color': newColor,
                                'fill-outline-color': newColor,
                                'fill-opacity': 0.1
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-midpoint',
                            'type': 'circle',
                            'filter': ['all', ['==', '$type', 'Point'],
                                ['==', 'meta', 'midpoint']
                            ],
                            'paint': {
                                'circle-radius': 3,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-stroke-inactive',
                            'type': 'line',
                            'filter': ['all', ['==', 'active', 'false'],
                                ['==', '$type', 'Polygon'],
                                ['!=', 'mode', 'static']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-stroke-active',
                            'type': 'line',
                            'filter': ['all', ['==', 'active', 'true'],
                                ['==', '$type', 'Polygon']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-dasharray': [0.2, 2],
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-line-inactive',
                            'type': 'line',
                            'filter': ['all', ['==', 'active', 'false'],
                                ['==', '$type', 'LineString'],
                                ['!=', 'mode', 'static']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-line-active',
                            'type': 'line',
                            'filter': ['all', ['==', '$type', 'LineString'],
                                ['==', 'active', 'true']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-dasharray': [0.2, 2],
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
                            'type': 'circle',
                            'filter': ['all', ['==', 'meta', 'vertex'],
                                ['==', '$type', 'Point'],
                                ['!=', 'mode', 'static']
                            ],
                            'paint': {
                                'circle-radius': 5,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-and-line-vertex-inactive',
                            'type': 'circle',
                            'filter': ['all', ['==', 'meta', 'vertex'],
                                ['==', '$type', 'Point'],
                                ['!=', 'mode', 'static']
                            ],
                            'paint': {
                                'circle-radius': 3,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-point-point-stroke-inactive',
                            'type': 'circle',
                            'filter': ['all', ['==', 'active', 'false'],
                                ['==', '$type', 'Point'],
                                ['==', 'meta', 'feature'],
                                ['!=', 'mode', 'static']
                            ],
                            'paint': {
                                'circle-radius': 5,
                                'circle-opacity': 1,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-point-inactive',
                            'type': 'circle',
                            'filter': ['all', ['==', 'active', 'false'],
                                ['==', '$type', 'Point'],
                                ['==', 'meta', 'feature'],
                                ['!=', 'mode', 'static']
                            ],
                            'paint': {
                                'circle-radius': 3,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-point-stroke-active',
                            'type': 'circle',
                            'filter': ['all', ['==', '$type', 'Point'],
                                ['==', 'active', 'true'],
                                ['!=', 'meta', 'midpoint']
                            ],
                            'paint': {
                                'circle-radius': 7,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-point-active',
                            'type': 'circle',
                            'filter': ['all', ['==', '$type', 'Point'],
                                ['!=', 'meta', 'midpoint'],
                                ['==', 'active', 'true']
                            ],
                            'paint': {
                                'circle-radius': 5,
                                'circle-color': newColor
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-fill-static',
                            'type': 'fill',
                            'filter': ['all', ['==', 'mode', 'static'],
                                ['==', '$type', 'Polygon']
                            ],
                            'paint': {
                                'fill-color': newColor,
                                'fill-outline-color': newColor,
                                'fill-opacity': 0.1
                            }
                        },
                        {
                            'id': 'gl-draw-polygon-stroke-static',
                            'type': 'line',
                            'filter': ['all', ['==', 'mode', 'static'],
                                ['==', '$type', 'Polygon']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-line-static',
                            'type': 'line',
                            'filter': ['all', ['==', 'mode', 'static'],
                                ['==', '$type', 'LineString']
                            ],
                            'layout': {
                                'line-cap': 'round',
                                'line-join': 'round'
                            },
                            'paint': {
                                'line-color': newColor,
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-point-static',
                            'type': 'circle',
                            'filter': ['all', ['==', 'mode', 'static'],
                                ['==', '$type', 'Point']
                            ],
                            'paint': {
                                'circle-radius': 5,
                                'circle-color': newColor
                            }
                        },

            
                        {
                            'id': 'gl-draw-polygon-color-picker',
                            'type': 'fill',
                            'filter': ['all', ['==', '$type', 'Polygon'],
                                ['has', 'user_portColor']
                            ],
                            'paint': {
                                'fill-color': ['get', 'user_portColor'],
                                'fill-outline-color': ['get', 'user_portColor'],
                                'fill-opacity': 0.5
                            }
                        },
                        {
                            'id': 'gl-draw-line-color-picker',
                            'type': 'line',
                            'filter': ['all', ['==', '$type', 'LineString'],
                                ['has', 'user_portColor']
                            ],
                            'paint': {
                                'line-color': ['get', 'user_portColor'],
                                'line-width': 2
                            }
                        },
                        {
                            'id': 'gl-draw-point-color-picker',
                            'type': 'circle',
                            'filter': ['all', ['==', '$type', 'Point'],
                                ['has', 'user_portColor']
                            ],
                            'paint': {
                                'circle-radius': 3,
                                'circle-color': ['get', 'user_portColor']
                            }
                        },
            
                    ]
                });
                map.addControl(draw, 'top-left');
                map.addControl(
                    new mapboxgl.GeolocateControl({
                        positionOptions: {
                        enableHighAccuracy: true
                        },
                        // When active the map will receive updates to the device's location as it changes.
                        trackUserLocation: true,
                        // Draw an arrow next to the location dot to indicate which direction the device is heading.
                        showUserHeading: true
                    })
                );


                // If an item was previously added to the map for this deficiency, add it back now, and adjust bounds to fit
                if($(map_container.parentNode).find("input[id*=feature_string]").val()){
                    var oldCoordinates = $(map_container.parentNode).find("input[id*=old_coordinates]").val()
                    console.log("OLD COORDS?")
                    console.log(oldCoordinates)
                    draw.add(jsonObj)

                    map.fitBounds(bbox(jsonObj), {
                        padding: 30,
                        maxZoom: 18
                    })
                }
        
                var drawFeatureID;
        
                var setDrawFeature = function(e) {
                if (e.features.length && e.features[0].type === 'Feature') {
                    var feat = e.features[0];
                    drawFeatureID = feat.id;
                }
                }
        
                // Add zoom controls
                map.addControl(new mapboxgl.NavigationControl());
        
                map.on('draw.create', function (e) {

                    setDrawFeature(e);
                    draw.setFeatureProperty(drawFeatureID, 'portColor', newColor);
        
                    var data = draw.getAll();
                        $(e.target._container.parentNode).find("input[id*=feature_string]").val(JSON.stringify(data));
                        var mapData = map.getCanvas().toDataURL();
                        // console.log(mapData);
                    $(e.target._container.parentNode).find("input[id*=map_image]").val(JSON.stringify(mapData));
                });
        
                map.on('draw.delete', function (e) {
                    var data = draw.getAll();
                    $(e.target._container.parentNode).find("input[id*=feature_string]").val(JSON.stringify(data));
                    var mapData = map.getCanvas().toDataURL();
                    $(e.target._container.parentNode).find("input[id*=map_image]").val(JSON.stringify(mapData));
                });
        
                map.on('draw.update', function (e) {
                    setDrawFeature(e);
                    draw.setFeatureProperty(drawFeatureID, 'portColor', newColor);
                    var data = draw.getAll();
                    $(e.target._container.parentNode).find("input[id*=feature_string]").val(JSON.stringify(data));
                    var mapData = map.getCanvas().toDataURL();
                    $(e.target._container.parentNode).find("input[id*=map_image]").val(JSON.stringify(mapData));
                });
            }
        });

    });

    $('.simple_form').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
      console.log("after insert");
      // For handling NEW action items. 
      switch (originalEvent.target.dataset.association) {
        case "swpp_corrective_action":
          $("select[id$='_deficiency']").editableSelect();
            var i = 0;
            const map_container = insertedItem[0].getElementsByClassName("map")[0];
            $(map_container.parentNode).find("input[id*=deficiency_map_id]").val(document.getElementById("deficiencyMapId").dataset.basemapId)

            const map = new mapboxgl.Map({
                container: map_container.id, // container ID
                style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
                center: [map_container.dataset.longitude, map_container.dataset.latitude], // starting position [lng, lat]
                zoom: 16, // starting zoom
                preserveDrawingBuffer: true,
            });          
            map.on('load', function() {
                // If using uploaded image, add image as "points" layer to map
                if(document.getElementById("deficiencyMapId").dataset.basemapId){ 
                    var lowestLat = 0;
                    var lowestLng = 0;
                    var highestLat = 0;
                    var highestLng = 0;

                    const getMeta = (url, cb) => {
                        const img = new Image();
                        img.onload = () => cb(null, img);
                        img.onerror = (err) => cb(err);
                        img.src = url;
                    };
                    
                    
                    getMeta(map_container.dataset.basemapurl + '?' + Math.random().toString().split('.').join(""), (err, img) => {
                        if(false){
                            lowestLat = Number(map_container.dataset.latitude) + img.naturalHeight/500000;
                            lowestLng = Number(map_container.dataset.longitude) - img.naturalWidth/500000;
                            highestLat = Number(map_container.dataset.latitude) - img.naturalHeight/500000;
                            highestLng = Number(map_container.dataset.longitude) + img.naturalWidth/500000;
                        } else {
                            const mapPixelCenter = map.project(map.getCenter());
                            const lowestLatPixel = Number(mapPixelCenter.y) - Number(img.naturalHeight)/10;
                            const lowestLngPixel = Number(mapPixelCenter.x) - Number(img.naturalWidth)/10;
                            const highestLatPixel = Number(mapPixelCenter.y) + Number(img.naturalHeight)/10;
                            const highestLngPixel = Number(mapPixelCenter.x) + Number(img.naturalWidth)/10;
                            lowestLat = Number(map.unproject([lowestLngPixel, lowestLatPixel]).lat);
                            lowestLng = Number(map.unproject([lowestLngPixel, lowestLatPixel]).lng);
                            highestLat = Number(map.unproject([highestLngPixel, highestLatPixel]).lat);
                            highestLng = Number(map.unproject([highestLngPixel, highestLatPixel]).lng);
                        }
                        const event = new Event('resize');
                        window.dispatchEvent(event);
                    });

                    map.on('idle', function() {
                        if(i == 0 && highestLng == 0){
                            setTimeout(function(){
                                const event = new Event('resize');
                                window.dispatchEvent(event);
                            }, 250)
    
                        }
                        if(i == 0 && highestLng != 0){
                            console.log("1. Adding image..")
                            
                            map.addSource('basemap', {
                                'type': 'image',
                                'url': map_container.dataset.basemapurl + '?' + Math.random().toString().split('.').join(""),
                                'coordinates': [
                                        [lowestLng, lowestLat],
                                        [highestLng, lowestLat],
                                        [highestLng, highestLat],
                                        [lowestLng, highestLat]
                                ]
                            });
                            map.addLayer({
                                id: 'image',
                                'type': 'raster',
                                'source': 'basemap',
                                'paint': {
                                    'raster-fade-duration': 0
                                }
                            });
    
                            //console.log(document.getElementsByClassName("map")[0].dataset.basemapurl)
                            const event = new Event('resize');
                            window.dispatchEvent(event);
                            i = 1;
                        }
                        if(i == 1 && !map.getLayer("image")){
                            setTimeout(function(){
                                const event = new Event('resize');
                                window.dispatchEvent(event);
                            }, 250)
    
                        }
                        if(i == 1 && map.getLayer("image")){
    
                            console.log("Image added!")
                            console.log("2. Fitting map bounds.")
    
                            map.fitBounds([[Number(lowestLng), Number(lowestLat)], [Number(highestLng), Number(highestLat)]], {
                                padding: 0
                                //maxZoom: 18
                            });
    
                            i = 2;
    
                            const event = new Event('resize');
                            window.dispatchEvent(event);
                        }
                        if(i == 1 && map.getLayer("image")){
    
                            console.log("Image loaded!")
                            console.log("2. Fitting map bounds.")
    
                            map.fitBounds([[Number(lowestLng), Number(lowestLat)], [Number(highestLng), Number(highestLat)]], {
                                padding: 0
                                //maxZoom: 18
                            });
    
                            i = 2;
    
                            const event = new Event('resize');
                            window.dispatchEvent(event);
                        }
                        if(i == 2 && map.getLayer("image")){
                            console.log("3. Making unneeded layers invisible.")
                            
                            map.style.stylesheet.layers.forEach(function(layer) {
                                if((layer.id != "image" && layer.source != "mapbox-gl-draw-hot") && layer.source != "mapbox-gl-draw-cold"){
                                    map.setLayoutProperty(layer.id,"visibility", "none");
                                    //console.log(layer.layout.visibility)
                                    //console.log(layer)   
                                } 
                            });
    
                            i = 3;
    
                            const event = new Event('resize');
                            window.dispatchEvent(event);
                        }
                        if (i == 3 && map.getLayer("gl-draw-polygon-fill-inactive.cold") && map.getLayer("image")){
                            console.log("3. Interchanging map layers.")
                            map.moveLayer("image","gl-draw-polygon-fill-inactive.cold")
                            console.log("LAYERS AND OTHER MAP DATA (before drag):");
                            console.log(map.getStyle().layers);
                            console.log(map)
                            //var layer = map.getLayer("points");
                            //console.log(layer)
                            i = 4;
                        }
                        if (i == 4){
                            console.log("4. Resize map.")
                            //map.resize()
                            i = 5;
                        }
                    });
                    
                }
                
                // Add draw controls and styles, no matter what type of map

                map.addControl(new mapboxgl.NavigationControl());

                var draw = new MapboxDraw({
                controls: {
                    combine_features: false,
                    uncombine_features: false
                }, 
                styles: [

        
        
                    {
                        'id': 'gl-draw-polygon-fill-inactive',
                        'type': 'fill',
                        'filter': ['all', ['==', 'active', 'false'],
                            ['==', '$type', 'Polygon'],
                            ['!=', 'mode', 'static']
                        ],
                        'paint': {
                            'fill-color': newColor,
                            'fill-outline-color': newColor,
                            'fill-opacity': 0.1
                        }
                    },
                    {
                        'id': 'gl-draw-polygon-fill-active',
                        'type': 'fill',
                        'filter': ['all', ['==', 'active', 'true'],
                            ['==', '$type', 'Polygon']
                        ],
                        'paint': {
                            'fill-color': newColor,
                            'fill-outline-color': newColor,
                            'fill-opacity': 0.1
                        }
                    },
                    {
                        'id': 'gl-draw-polygon-midpoint',
                        'type': 'circle',
                        'filter': ['all', ['==', '$type', 'Point'],
                            ['==', 'meta', 'midpoint']
                        ],
                        'paint': {
                            'circle-radius': 3,
                            'circle-color': newColor
                        }
                    },
                    {
                        'id': 'gl-draw-polygon-stroke-inactive',
                        'type': 'line',
                        'filter': ['all', ['==', 'active', 'false'],
                            ['==', '$type', 'Polygon'],
                            ['!=', 'mode', 'static']
                        ],
                        'layout': {
                            'line-cap': 'round',
                            'line-join': 'round'
                        },
                        'paint': {
                            'line-color': newColor,
                            'line-width': 2
                        }
                    },
                    {
                        'id': 'gl-draw-polygon-stroke-active',
                        'type': 'line',
                        'filter': ['all', ['==', 'active', 'true'],
                            ['==', '$type', 'Polygon']
                        ],
                        'layout': {
                            'line-cap': 'round',
                            'line-join': 'round'
                        },
                        'paint': {
                            'line-color': newColor,
                            'line-dasharray': [0.2, 2],
                            'line-width': 2
                        }
                    },
                    {
                        'id': 'gl-draw-line-inactive',
                        'type': 'line',
                        'filter': ['all', ['==', 'active', 'false'],
                            ['==', '$type', 'LineString'],
                            ['!=', 'mode', 'static']
                        ],
                        'layout': {
                            'line-cap': 'round',
                            'line-join': 'round'
                        },
                        'paint': {
                            'line-color': newColor,
                            'line-width': 2
                        }
                    },
                    {
                        'id': 'gl-draw-line-active',
                        'type': 'line',
                        'filter': ['all', ['==', '$type', 'LineString'],
                            ['==', 'active', 'true']
                        ],
                        'layout': {
                            'line-cap': 'round',
                            'line-join': 'round'
                        },
                        'paint': {
                            'line-color': newColor,
                            'line-dasharray': [0.2, 2],
                            'line-width': 2
                        }
                    },
                    {
                        'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
                        'type': 'circle',
                        'filter': ['all', ['==', 'meta', 'vertex'],
                            ['==', '$type', 'Point'],
                            ['!=', 'mode', 'static']
                        ],
                        'paint': {
                            'circle-radius': 5,
                            'circle-color': newColor
                        }
                    },
                    {
                        'id': 'gl-draw-polygon-and-line-vertex-inactive',
                        'type': 'circle',
                        'filter': ['all', ['==', 'meta', 'vertex'],
                            ['==', '$type', 'Point'],
                            ['!=', 'mode', 'static']
                        ],
                        'paint': {
                            'circle-radius': 3,
                            'circle-color': newColor
                        }
                    },
                    {
                        'id': 'gl-draw-point-point-stroke-inactive',
                        'type': 'circle',
                        'filter': ['all', ['==', 'active', 'false'],
                            ['==', '$type', 'Point'],
                            ['==', 'meta', 'feature'],
                            ['!=', 'mode', 'static']
                        ],
                        'paint': {
                            'circle-radius': 5,
                            'circle-opacity': 1,
                            'circle-color': newColor
                        }
                    },
                    {
                        'id': 'gl-draw-point-inactive',
                        'type': 'circle',
                        'filter': ['all', ['==', 'active', 'false'],
                            ['==', '$type', 'Point'],
                            ['==', 'meta', 'feature'],
                            ['!=', 'mode', 'static']
                        ],
                        'paint': {
                            'circle-radius': 3,
                            'circle-color': newColor
                        }
                    },
                    {
                        'id': 'gl-draw-point-stroke-active',
                        'type': 'circle',
                        'filter': ['all', ['==', '$type', 'Point'],
                            ['==', 'active', 'true'],
                            ['!=', 'meta', 'midpoint']
                        ],
                        'paint': {
                            'circle-radius': 7,
                            'circle-color': newColor
                        }
                    },
                    {
                        'id': 'gl-draw-point-active',
                        'type': 'circle',
                        'filter': ['all', ['==', '$type', 'Point'],
                            ['!=', 'meta', 'midpoint'],
                            ['==', 'active', 'true']
                        ],
                        'paint': {
                            'circle-radius': 5,
                            'circle-color': newColor
                        }
                    },
                    {
                        'id': 'gl-draw-polygon-fill-static',
                        'type': 'fill',
                        'filter': ['all', ['==', 'mode', 'static'],
                            ['==', '$type', 'Polygon']
                        ],
                        'paint': {
                            'fill-color': newColor,
                            'fill-outline-color': newColor,
                            'fill-opacity': 0.1
                        }
                    },
                    {
                        'id': 'gl-draw-polygon-stroke-static',
                        'type': 'line',
                        'filter': ['all', ['==', 'mode', 'static'],
                            ['==', '$type', 'Polygon']
                        ],
                        'layout': {
                            'line-cap': 'round',
                            'line-join': 'round'
                        },
                        'paint': {
                            'line-color': newColor,
                            'line-width': 2
                        }
                    },
                    {
                        'id': 'gl-draw-line-static',
                        'type': 'line',
                        'filter': ['all', ['==', 'mode', 'static'],
                            ['==', '$type', 'LineString']
                        ],
                        'layout': {
                            'line-cap': 'round',
                            'line-join': 'round'
                        },
                        'paint': {
                            'line-color': newColor,
                            'line-width': 2
                        }
                    },
                    {
                        'id': 'gl-draw-point-static',
                        'type': 'circle',
                        'filter': ['all', ['==', 'mode', 'static'],
                            ['==', '$type', 'Point']
                        ],
                        'paint': {
                            'circle-radius': 5,
                            'circle-color': newColor
                        }
                    },
        
        
                    {
                        'id': 'gl-draw-polygon-color-picker',
                        'type': 'fill',
                        'filter': ['all', ['==', '$type', 'Polygon'],
                            ['has', 'user_portColor']
                        ],
                        'paint': {
                            'fill-color': ['get', 'user_portColor'],
                            'fill-outline-color': ['get', 'user_portColor'],
                            'fill-opacity': 0.5
                        }
                    },
                    {
                        'id': 'gl-draw-line-color-picker',
                        'type': 'line',
                        'filter': ['all', ['==', '$type', 'LineString'],
                            ['has', 'user_portColor']
                        ],
                        'paint': {
                            'line-color': ['get', 'user_portColor'],
                            'line-width': 2
                        }
                    },
                    {
                        'id': 'gl-draw-point-color-picker',
                        'type': 'circle',
                        'filter': ['all', ['==', '$type', 'Point'],
                            ['has', 'user_portColor']
                        ],
                        'paint': {
                            'circle-radius': 3,
                            'circle-color': ['get', 'user_portColor']
                        }
                    },
        
                ]
                });
                // Map#addControl takes an optional second argument to set the position of the control.
                // If no position is specified the control defaults to `top-right`. See the docs
                // for more details: https://docs.mapbox.com/mapbox-gl-js/api/#map#addcontrol
                map.addControl(draw, 'top-left');


                // Add geolocate, but only if using satellite map
                if(!document.getElementById("deficiencyMapId").dataset.basemapId){ 
                    map.addControl(
                        new mapboxgl.GeolocateControl({
                            positionOptions: {
                            enableHighAccuracy: true
                            },
                            // When active the map will receive updates to the device's location as it changes.
                            trackUserLocation: true,
                            // Draw an arrow next to the location dot to indicate which direction the device is heading.
                            showUserHeading: true
                        })
                    );
                }

                // Add draw control and features
                var drawFeatureID;

                var setDrawFeature = function(e) {
                    if (e.features.length && e.features[0].type === 'Feature') {
                        var feat = e.features[0];
                        drawFeatureID = feat.id;
                    }
                }


                map.on('draw.create', function (e) {
                    // console.log(e.features);
                    // console.log(Draw.getAll());
                    setDrawFeature(e);
                    draw.setFeatureProperty(drawFeatureID, 'portColor', newColor);
                    var data = draw.getAll();
                    $(e.target._container.parentNode).find("input[id*=feature_string]").val(JSON.stringify(data));
                    var mapData = map.getCanvas().toDataURL();
                    // console.log(mapData);
                    $(e.target._container.parentNode).find("input[id*=map_image]").val(JSON.stringify(mapData));
                });

                map.on('draw.delete', function (e) {
                    var data = draw.getAll();
                    $(e.target._container.parentNode).find("input[id*=feature_string]").val(JSON.stringify(data));
                    var mapData = map.getCanvas().toDataURL();
                    $(e.target._container.parentNode).find("input[id*=map_image]").val(JSON.stringify(mapData));
                });

                map.on('draw.update', function (e) {
                    var data = draw.getAll();
                    draw.setFeatureProperty(drawFeatureID, 'portColor', newColor);
                    $(e.target._container.parentNode).find("input[id*=feature_string]").val(JSON.stringify(data));
                    var mapData = map.getCanvas().toDataURL();
                    $(e.target._container.parentNode).find("input[id*=map_image]").val(JSON.stringify(mapData));
                });

                // If using uploaded image, reconfigure map appropriately on idle
                
            });
      
                

            break
          
        case "location":
          
          break
        case "swpp_inspection_subquestion":
          break
        default:
          console.log("Default")
      }
    });

    $('.simple_form').on('cocoon:before-insert', function(e, insertedItem, originalEvent) {
      let count, regex, label, existing_items, removed_existing_items, item_count, total
      console.log("Before insert");
      switch (originalEvent.target.dataset.association) {
        case "swpp_corrective_action":
            insertedItem[0].getElementsByClassName("map")[0].id = Date.now();

            //if (document.getElementById("satelliteMap").dataset.satelliteMap) {
                // Set random id for map div
            //    insertedItem[0].getElementsByClassName("map")[0].id = Date.now()
            //} else {
            //    
            //    
            //}
          
            // Update labels and ref ids
            count = insertedItem[0].dataset.actionItemCount;
            parent = $(e.target.parentNode.parentNode);
            regex = /^([^.]+)/;
            label = parent.find("input[id*=title]")[0].value.match(regex)[0];
            existing_items = parent.find('.actionitem-fields .nested-fields:visible').filter('[data-persisted="true"]')
            removed_existing_items = parent.find('.actionitem-fields .nested-fields:hidden').filter('[data-persisted="true"]')
            count = parseInt(count) - parseInt(existing_items.length) - parseInt(removed_existing_items.length)
            item_count = parent.find('.actionitem-fields .nested-fields:visible').length
            total = parseInt(count) + parseInt(item_count) + 1
            insertedItem.find('.ref-id').val(label + "." + total)
            break
        case "location":
            break
        case "swpp_inspection_subquestion":
            parent = $(e.target.parentNode.parentNode);
            count = parent.find('.subquestion-fields .nested-fields:visible').length + 1;
            regex = /^([^.]+)/;
            label = parent.find("input[id*=title]")[0].value.match(regex)[0];
            insertedItem.find('.ref-id').val(label + "." + count)
            break
        default:
            console.log("Default")
      }

    });

    $('.simple_form').on('cocoon:after-remove', function(e, removedItem, originalEvent){
      console.log("after remove");

      switch (originalEvent.target.dataset.association) {
        case "swpp_corrective_action":
          let count, regex, label, existing_items, removed_existing_items
          count = removedItem[0].dataset.actionItemCount;
          parent = $(e.target.parentNode);
          regex = /^([^.]+)/;
          label = parent.find("input[id*=title]")[0].value.match(regex)[0];
          existing_items = parent.find('.actionitem-fields .nested-fields:visible').filter('[data-persisted="true"]')
          removed_existing_items = parent.find('.actionitem-fields .nested-fields:hidden').filter('[data-persisted="true"]')
          count = parseInt(count) - parseInt(removed_existing_items.length)
          parent.find('.actionitem-fields .nested-fields:visible').filter('[data-persisted="false"]').each(function ( index ){
            $(this).find('.ref-id').val(label + '.' + ( count + index + 1 ));
          })
          break
        case "location":
          break
        case "swpp_inspection_subquestion":
          parent = $(e.target.parentNode);
          regex = /^([^.]+)/;
          label = parent.find("input[id*=title]")[0].value.match(regex)[0];
          parent.find('.subquestion-fields .nested-fields:visible').each(function ( index ){
            $(this).find('.ref-id').val(label + '.' + (index + 1));
          })
          break
        default:
          console.log("Default")
      }
      
    });
  };

  if ( document.querySelector('body.pdf_swpp_inspections') ) {
    // Currently configured for PA Visual report form. Will need to be updated if other
    // PDF forms need to incorporate action items.

    $('.simple_form').on('cocoon:before-insert', function(e, insertedItem, originalEvent) {
      console.log(insertedItem[0].dataset);
      let count, regex, label, existing_items, removed_existing_items, item_count, total
      count = insertedItem[0].dataset.actionItemCount;

      // actionItemCount is undefined for non-action items like subquestions
      if (count == undefined) {
        parent = $(e.target.parentNode.parentNode);
        count = parent.find('.nested-fields:visible').length + 1;
        regex = /^([^.]+)/;
        insertedItem.find('.ref-id').val("26." + count)
        return
      } else {
        parent = $(e.target.parentNode);
        regex = /^([^.]+)/;
        existing_items = parent.find('.nested-fields:visible').filter('[data-persisted="true"]')
        removed_existing_items = parent.find('.nested-fields:hidden').filter('[data-persisted="true"]')
        count = parseInt(count) - parseInt(existing_items.length) - parseInt(removed_existing_items.length)
        item_count = parent.find('.nested-fields:visible').length
        total = parseInt(count) + parseInt(item_count) + 1
        insertedItem.find('.ref-id').val("26." + total)
      }
    });

    $('.simple_form').on('cocoon:after-remove', function(e, removedItem, originalEvent){
      console.log("After remove");
      let count, regex, label, existing_items, removed_existing_items
      count = removedItem[0].dataset.actionItemCount;

      // actionItemCount is undefined for non-action items like subquestions
      if (count == undefined) {
        parent = $(e.target.parentNode);
        regex = /^([^.]+)/;
        parent.find('.nested-fields:visible').each(function ( index ){
          $(this).find('.ref-id').val('26.' + (index + 1));
        })
      } else {
        parent = $(e.target);
        regex = /^([^.]+)/;
        existing_items = parent.find('.nested-fields:visible').filter('[data-persisted="true"]')
        removed_existing_items = parent.find('.nested-fields:hidden').filter('[data-persisted="true"]')
        count = parseInt(count) - parseInt(removed_existing_items.length)
        parent.find('.nested-fields:visible').filter('[data-persisted="false"]').each(function ( index ){
          $(this).find('.ref-id').val('26.' + ( count + index + 1 ));
        })
      }
    });
  };

});
