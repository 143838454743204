$(document).on('turbolinks:load', function() {

	if ( document.querySelector('body.dashboards.overview') ) {

		// https://stackoverflow.com/questions/36217098/checkboxes-only-work-on-first-page-datatables-rails
		var table = $('#dtDashboardOpenActionItems').DataTable({
			processing: true,
	    // serverSide: true,
	    ajax: {
	      url: $('#dtDashboardOpenActionItems').data('source')
	      // type: 'POST'
	    },
	    pagingType: "full_numbers",
			lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
	    columns: [
				{data: "id"},
        {data: "ref_id"},
				{data: "site_name"},
        {data: "status"},
        {data: "action_type"},
        {data: "days_open"},
        {data: "deficiency"},
        {data: "location"},
				{data: "overdue"}
	    ],
			columnDefs: [
				{
					targets: 0,
					checkboxes: {
						selectRow: true
					},
					orderable: false
				},
				{
					targets: 8,
					visible: false
				},
				{
					targets: 3,
					orderable: true
				}
			],
			select: {
				style: "multi"
			},
			createdRow: function( row, data, dataIndex){
				if( data["overdue"] == true ){
	      	$(row).addClass('table-warning');
	      }
      },
			stateSave: false
	    // pagingType is optional, if you want full pagination controls.
	    // Check dataTables documentation to learn more about
	    // available options.
		});

		var sites_table = $('#dtDashboardSites').DataTable({
			processing: true,
	    serverSide: true,
			order: [
				[0, "asc"]
			],
	    ajax: {
	      url: $('#dtDashboardSites').data('source'),
	      type: 'POST'
	    },
	    pagingType: "full_numbers",
	    columns: [
				{data: "site_name"},
        {data: "company_name"},
        {data: "next_routine_due"},
        {data: "open_items"},
        {data: "rainfall_total"},
				{data: "over_threshold"}
	    ],
			columnDefs: [
				{
					targets: 5,
					visible: false
				}
			],
			language: {
				processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
			},
			createdRow: function( row, data, dataIndex){
				if( data["over_threshold"] == "true" ){
	      	$(row.cells[4]).addClass('deep-orange lighten-3');
	      }
      }
	    // pagingType is optional, if you want full pagination controls.
	    // Check dataTables documentation to learn more about
	    // available options.
		});


		// Handle form submission event
		$('form').on('submit', function(e){
		  var form = this;

		  var rows_selected = table.column(0).checkboxes.selected();

		  // Iterate over all selected checkboxes
		  $.each(rows_selected, function(index, rowId){
		     // Create a hidden element
		     $(form).append(
		         $('<input>')
		            .attr('type', 'hidden')
		            .attr('name', 'id[]')
		            .val(rowId)
		     );
		  });
		});

		var sites_table_v2 = $('#dtDashboardSitesV2').DataTable({
			columns: [
				{data: "id"},
        {data: "site_name"},
		{data: "company_name"},
        {data: "next_routine_inspection_date"},
        {data: "open_action_items_count"},
        {data: "last_rainfall_total"},
        {data: "rain_event_triggered"}
	    ]
	  });
		sites_table_v2.column( 0 ).visible( false )
		sites_table_v2.column( 6 ).visible( false )

	  sites_table_v2.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
	    var row = this
	    var rowData = this.data()

	    $.ajax({
	      url: $('#dtDashboardSitesV2').data('source'),
				type: 'GET',
				data: {
					site_id: sites_table_v2.cell( this, 0 ).data()
				},
	      dataType: 'JSON',
	      success: function ( data, textStatus, jqXHR ) {
	        if ( textStatus == "nocontent" ) {
	          	rowData["open_action_items_count"] = "No data"
	        } else {
	          	rowData["open_action_items_count"] = data[0]
	          	rowData["last_rainfall_total"] = data[1]
				rowData["rain_event_triggered"] = data[2]
	        }
	        row.data( rowData ).draw();
					if (rowData["rain_event_triggered"] == true) {
						$(sites_table_v2.cell( rowIdx, 5 ).node()).addClass('deep-orange lighten-3')
					}
	      }
	    })
	  })

	};

});
