$(document).on("turbolinks:load", function() {
  if ( document.querySelector('body.map_revisions.new, body.map_revisions.edit') ) {
    $('form').keyup(function() {
      if ( $('#map_revision_details').val() == "" ) {
        $('#map_revision_submit').attr('disabled', 'disabled'); // updated according to http://stackoverflow.com/questions/7637790/how-to-remove-disabled-attribute-with-jquery-ie
      } else {
        $('#map_revision_submit').removeAttr('disabled'); // updated according to http://stackoverflow.com/questions/7637790/how-to-remove-disabled-attribute-with-jquery-ie
      }
    });

    const $valueSpan = $('.valueSpanLineWidth');
    const $value = $('#drawing-line-width');
    $valueSpan.html($value.val());
    $value.on('input change', () => {
      $valueSpan.html($value.val());
    });

    const $valueSpanScale = $('.valueSpanScale');
    const $valueScale = $('#item-size-scale');
    $valueSpanScale.html($valueScale.val());
    $valueScale.on('input change', () => {
      $valueSpanScale.html($valueScale.val());
    });

    const $valueSpanOpacity = $('.valueSpanOpacity');
    const $valueOpacity = $('#item-opacity-scale');
    $valueSpanOpacity.html($valueOpacity.val());
    $valueOpacity.on('input change', () => {
      $valueSpanOpacity.html($valueOpacity.val());
    });

    const $valueSpanImgWidth = $('.valueSpanImgWidth');
    const $valueImgWidth = $('#img-width-scale');
    $valueSpanImgWidth.html($valueImgWidth.val());
    $valueImgWidth.on('input change', () => {
      $valueSpanImgWidth.html($valueImgWidth.val());
    });

    document.querySelector('#small-screen-info').innerHTML = "Map editing requires a screen width of at least 992px. Rotate tablets to landscape view."
  };

  if ( document.querySelector('body.map_revisions.show, body.map_revisions.index') ) {
    document.getElementsByClassName("map-revision-img").forEach(function(element) {

      // Remove any existing img tags to prevent re-adding them during browser back
      // actions
      element.getElementsByTagName("img").forEach(function(elem) {
        elem.remove()
      })

      loadMapImg(element)
    })
    function loadMapImg(element) {
      const mapId = element.dataset.mapId
      const siteId = element.dataset.siteId
      const mapRevisionId = element.dataset.mapRevisionId
      $.ajax({
        url: "/sites/" + siteId + "/maps/" + mapId + "/map_revisions/" + mapRevisionId,
  			method: "GET",
  			dataType: "json",
  			// data: {map_revision_id: mapRevisionId},
  			error: function (xhr, status, error) {
  				console.error('AJAX Error: ' + status + error);
  			},
  			success: function (response) {
  				const mapURL = response["map_revision"]["map_file"]["thumb"]["url"]
          var image = document.createElement('img')
          image.className = "img-fluid"
          image.src = mapURL
          element.appendChild(image)
  			}
      })
    }
  }; // end if
});
