$(document).on("turbolinks:load", function () {
  document.getElementsByClassName("static-canvas").forEach( function(canvas) {
    var staticCanvas = new fabric.StaticCanvas(canvas);
    jQuery.ajax({
      url: canvas.dataset.url,
      type: "GET",
      dataType: 'JSON',
      success: function ( data, textStatus, jqXHR ) {
        var jsonObj = JSON.parse(data.json)
        fabric.util.enlivenObjects([jsonObj], function(objects) {
          objects.forEach(function(obj) {
            staticCanvas.add(obj)
            obj.scaleToWidth(canvas.width - 5)
            obj.scaleToHeight(canvas.height - 5)
            staticCanvas.centerObject(obj)
          })
        })
      }
    })
  })
})
