$(document).on('turbolinks:load', function() {



  $('.simple_form').on('cocoon:after-insert', function(e, removedItem, originalEvent){
  });

});

// https://231webdev.com/carrierwave-image-upload-validation-error-message-issue/
function validateFiles(inputFile) {
  console.log(inputFile);
  var maxExceededMessage = "This file exceeds the maximum allowed file size (8 MB)";
  var extErrorMessage = "Only image file with extensions: .jpg, .jpeg, .gif or .png are allowed";
  var allowedExtension = ["jpg", "jpeg", "gif", "png"];
  var extName;
  var maxFileSize = $(inputFile).data('max-file-size');
  var sizeExceeded = false;
  var extError = false;

  $.each(inputFile.files, function() {
    if (this.size && maxFileSize && this.size > parseInt(maxFileSize))
      {sizeExceeded=true;};
    extName = this.name.split('.').pop();
    if ($.inArray(extName, allowedExtension) == -1) {extError=true;};
  });

  if (sizeExceeded) {
    window.alert(maxExceededMessage);
    $(inputFile).val('');
  };

  if (extError) {
    window.alert(extErrorMessage);
    $(inputFile).val('');
  };

  if (!extError && !sizeExceeded) {
    readURL(inputFile);
  }
};
