$(document).on("turbolinks:load", function () {

  var rain_events_current_table = $('#dtRainEventsReportCurrent').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ]
  });

  rain_events_current_table.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
    var row = this
    var rowData = this.data()

    $.ajax({
      url: "https://api.weather.com/v2/pws/observations/current?stationId=" + rain_events_current_table.cell( this, 2 ).data() + "&format=json&units=e&apiKey=e6b0dad9ea964f64b0dad9ea96ef6418",
      type: 'GET',
      dataType: 'JSON',
      success: function ( data, textStatus, jqXHR ) {
        if ( textStatus == "nocontent" ) {
          // rowData[3] = "No data"
          rowData[4] = "No data"
        } else {
          rowData[3] = data.observations[0].imperial.precipTotal
          rowData[4] = data.observations[0].obsTimeLocal
        }
        row.data( rowData ).draw();
      }
    })

  })

});
