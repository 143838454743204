$(document).on("turbolinks:load", function() {

  if ( document.querySelector('body.proposals.show') ) {

    if ( $("#summarySwitch").length ) {
      summaryContent( $("#summarySwitch")[0] );
      coverPageContent( $("#coverPageSwitch")[0] );
    }

    function summaryContent(checkbox) {
      if (checkbox.checked) {
        $("#summaryContent").show();
      }
      else {
        $("#summaryContent").hide();
      }
    }

    function coverPageContent(checkbox) {
      if (checkbox.checked) {
        $("#coverPageContent").show();
        $("#fieldProposalCover").hide();
      }
      else {
        $("#coverPageContent").hide();
        $("#fieldProposalCover").show();
      }
    }
  }

  // https://stackoverflow.com/questions/36217098/checkboxes-only-work-on-first-page-datatables-rails
  var table = $('#dtProposalsAbattoir').DataTable({
    // processing: true,
    // serverSide: true,
    // ajax: {
    //   url: $('#dtProposalsAbattoir').data('source'),
    //   type: 'POST'
    // },
    // pagingType: "full_numbers",
    // columns: [
    //   {data: "id"},
    //   {data: "creator"},
    //   {data: "site/project name"},
    // ],
    columnDefs: [
      {
        targets: 0,
        checkboxes: {
          selectRow: true,
          selectAllPages: false
        }
      }
    ],
    select: {
      style: "multi"
    },
    order: [
      [1, "asc"]
    ]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });


  // Handle form submission event
  $('form').on('submit', function(e){
    var form = this;

    var rows_selected = table.column(0).checkboxes.selected();

    // Iterate over all selected checkboxes
    $.each(rows_selected, function(index, rowId){
       // Create a hidden element
       $(form).append(
           $('<input>')
              .attr('type', 'hidden')
              .attr('name', 'ids[]')
              .val(rowId)
       );
    });
  });

});
