$(document).on("turbolinks:load", function () {
  if ( document.querySelector('body.template_features') ) {
    var canvas = new fabric.Canvas('feature-editor', {
      preserveObjectStacking: true,
      backgroundColor: "rgba(0,0,0,0)"
    });
    const form = document.querySelector('[id*="_template_feature"]');
    if (form) form.addEventListener('submit', updateFormBeforeSubmit);

    const category = document.querySelector('[id="template_feature_category"]').value;
    var drawDashArray = [];
    var drawLineCap = 'round';
    var drawWidth = 6;
    var drawType = document.getElementById('feature-draw-type').value
    var drawColor = document.getElementById('feature-draw-color').value
    var opacity = 0.7;

    if(document.getElementById('template_feature_options').value){
      var str = document.getElementById('template_feature_options').value;
      var oldOpts = JSON.parse(str);
      if (oldOpts["type"]){
        document.getElementById('feature-draw-type').value = oldOpts["type"];
      }
      if (oldOpts["color"]){
        document.getElementById('feature-draw-color').value = oldOpts["color"];
      }
      if (oldOpts["dashArray"]){
        drawDashArray = oldOpts["dashArray"];
      }
      if (oldOpts["lineCap"]){
        drawLineCap = oldOpts["lineCap"];
      }
      if (oldOpts["width"]){
        drawWidth = oldOpts["width"]
      }
      if (oldOpts["opacity"] >= 0){
        opacity = oldOpts["opacity"]
        document.getElementById('opacity-slider').value = opacity
      }
    } 

    canvas.on("mouse:up", function () {
    })

    canvas.freeDrawingBrush.width = drawWidth

    // Show sections depending on category
    if (category == "draw") {
      document.getElementById('feature-shapes').style.display = 'none'
      document.getElementById('feature-icon-colors').style.display = 'none'
      document.getElementById('feature-icon-misc').style.display = 'none'
      document.getElementById('feature-section-draw').style.display = 'block'
      document.getElementById('item-opacity-section').style.display = 'block'
      // drawPreview({})
    } else if (category == "icon") {
      document.getElementById('feature-section-draw').style.display = 'none'
      document.getElementById('item-opacity-section').style.display = 'block'
    }

    loadFeatureJSON();

    canvas.on('selection:created', function (opt) {
      var obj = opt.target
      console.log(obj)
      // Update color pickers to match current selection
      document.getElementById('feature-icon-color-outline').value = obj.stroke
      document.getElementById('feature-icon-color-fill').value = obj.fill
      // Update size value
      document.getElementById('outline-width-value').innerHTML = ( Math.round(obj.strokeWidth) )
      // document.getElementById('opacity-slider').value = obj.opacity
    });

    canvas.on('selection:updated', function (opt) {
      // Update color pickers to match current selection
      document.getElementById('feature-icon-color-outline').value = opt.target.stroke
      document.getElementById('feature-icon-color-fill').value = opt.target.fill
      document.getElementById('opacity-slider').value = opt.target.opacity
    });

    document.getElementById('outline-width-slider').addEventListener('change', (event) => {
      if ( canvas.getActiveObject() ) {
        obj = canvas.getActiveObject();
        obj.strokeWidth = (parseFloat(event.target.value))
        obj.setCoords();
        obj.dirty = true
        canvas.renderAll()
        document.getElementById('outline-width-value').innerHTML = ( Math.round(obj.strokeWidth) )
      }
      
    })

    document.getElementById('opacity-slider').addEventListener('change', (event) => {
      opacity = (parseFloat(event.target.value));

      // Update the entire preview based on the selected opacity      
      canvas.forEachObject(function(obj) {
        obj.set('opacity', opacity);
      });
      canvas.renderAll();

      // This will update the actively selected object's opacity
      // if ( canvas.getActiveObject() ) {
      //   obj = canvas.getActiveObject();
      //   opacity = (parseFloat(event.target.value));
      //   obj.opacity = (parseFloat(event.target.value));
      //   obj.setCoords();
      //   obj.dirty = true
      //   canvas.renderAll()
      //   // document.getElementById('opacity-slider').innerHTML = ( Math.round(obj.opacity) )
      // }
      
    })


    function drawPreview(opt) {
      if (drawType == "square") {
        var texturePatternBrush = new fabric.PatternBrush(canvas);
        var initialZoom = 1
        var obj = new fabric.Rect({
          height: 55,
          width: 55,
          fill: drawColor
        })
        patternSourceCanvas = createDrawCanvas({
          height: 67,
          width: 67,
          obj: obj,
          initialZoom: initialZoom,
          color: drawColor
        })
        var pattern = new fabric.Pattern({
          source: patternSourceCanvas.getElement(),
          repeat: 'repeat'
        })
        canvas.add(
          new fabric.Rect({
            height: canvas.height,
            width: canvas.width,
            fill: pattern,
            objectCaching: false
          })
        )
      }
      else if ( drawType == "circle" ) {
        var texturePatternBrush = new fabric.PatternBrush(canvas);
        var initialZoom = 1
        var obj = new fabric.Circle({
          radius: 30,
          fill: drawColor
        })
        patternSourceCanvas = createDrawCanvas({
          height: 67,
          width: 67,
          obj: obj,
          initialZoom: initialZoom,
          color: drawColor
        })
        var pattern = new fabric.Pattern({
          source: patternSourceCanvas.getElement(),
          repeat: 'repeat'
        })
        canvas.add(
          new fabric.Rect({
            height: canvas.height,
            width: canvas.width,
            fill: pattern,
            objectCaching: false
          })
        )
      }
      else {
        const pointer = { x: 10, y: 10 }
        const options = { pointer, e:{  }} // required for Fabric 4.3.1
        canvas.freeDrawingBrush.width = drawWidth
        canvas.freeDrawingBrush.color = drawColor,
        canvas.freeDrawingBrush.strokeDashArray = drawDashArray,
        canvas.freeDrawingBrush.strokeLineCap = drawLineCap

        canvas.freeDrawingBrush.onMouseDown(pointer, options)
        canvas.freeDrawingBrush.onMouseMove({ x: 180, y: 50}, options )
        canvas.freeDrawingBrush.onMouseUp(options)

        canvas.freeDrawingBrush.onMouseDown({ x: 180, y: 50}, options)
        canvas.freeDrawingBrush.onMouseMove({ x: 10, y: 100}, options )
        canvas.freeDrawingBrush.onMouseUp(options)

        canvas.freeDrawingBrush.onMouseDown({ x: 10, y: 100}, options)
        canvas.freeDrawingBrush.onMouseMove({ x: 180, y: 180}, options )
        canvas.freeDrawingBrush.onMouseUp(options)
      }


    }

    function loadFeatureJSON() {
      const json = document.getElementById('template_feature_json').value
      if (json.length > 0) {
        const jsonObj = JSON.parse(json)
        fabric.util.enlivenObjects([jsonObj], function(objects) {
          objects.forEach(function(obj) {
            canvas.add(obj)
            canvas.centerObject(obj)
            // ungroup any grouped objects
            if (obj.type == "group") {
              var items = obj.getObjects()
              obj.destroy()
              canvas.remove(obj)
              for(var i = 0; i < items.length; i++) {
                canvas.add(items[i])
              }
              canvas.renderAll()
            }
          })
        })
      }
      // set opacity of preview
      canvas.forEachObject(function(obj) {
        obj.set('opacity', opacity);
      });
      canvas.renderAll();
    };

    function updateFormBeforeSubmit(event) {
      if (category == "draw") {
        var options = {
          category: category,
          type: drawType,
          color: drawColor,
          dashArray: drawDashArray,
          lineCap: drawLineCap,
          width: drawWidth,
          opacity: opacity
        }
        document.getElementById('template_feature_options').value = JSON.stringify(options)
      } else {
        var options = {
          opacity: opacity
        }
        document.getElementById('template_feature_options').value = JSON.stringify(options)
      }
      const objects = canvas.getObjects()
      var group = new fabric.Group(objects)
      var json = JSON.stringify(group)
      document.getElementById('template_feature_json').value = json;
      document.getElementById('template_feature_image').value = canvas.toDataURL({
        format: 'png',
        quality: 1
      });
      console.log(document.getElementById('template_feature_image').value)
      // Not sure what these are doing
      // var json = canvas.toJSON(['isActive', 'mapRevisionId', 'mapLegendItemId', 'featureId']);
      // var json_string = JSON.stringify(json);
    }

    function starPolygonPoints(spikeCount, outerRadius, innerRadius) {
      var rot = Math.PI / 2 * 3;
      var cx = outerRadius;
      var cy = outerRadius;
      var sweep = Math.PI / spikeCount;
      var points = [];
      var angle = 0;

      for (var i = 0; i < spikeCount; i++) {
        var x = cx + Math.cos(angle) * outerRadius;
        var y = cy + Math.sin(angle) * outerRadius;
        points.push({x: x, y: y});
        angle += sweep;

        x = cx + Math.cos(angle) * innerRadius;
        y = cy + Math.sin(angle) * innerRadius;
        points.push({x: x, y: y});
        angle += sweep
      }
      return (points);
    }

    function regularPolygonPoints(sideCount,radius){
      var sweep=Math.PI*2/sideCount;
      var cx=radius;
      var cy=radius;
      var points=[];
      for(var i=0;i<sideCount;i++){
          var x=cx+radius*Math.cos(i*sweep);
          var y=cy+radius*Math.sin(i*sweep);
          points.push({x:x,y:y});
      }
      return(points);
    }

    function createDrawCanvas(options) {
      var patternSourceCanvas = new fabric.StaticCanvas();
      patternSourceCanvas.setDimensions({
        width: options.width || 50,
        height: options.height ||50
      })
      patternSourceCanvas.add(options.obj);
      patternSourceCanvas.setZoom(options.initialZoom)
      patternSourceCanvas.renderAll();

      canvas.freeDrawingBrush.color = options.color;
      canvas.freeDrawingBrush.mapLegendItemId = options.mapLegendItemId;

      return patternSourceCanvas
    }

    // Drawing
    //////////


    document.getElementById('feature-draw-type').addEventListener('change', (event) => {
      drawType = event.target.value
      canvas.clear()
      if (event.target.value == "solid") {
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas)
        drawDashArray = []
        drawWidth = 6
        drawPreview()
      }
      else if (event.target.value == "dashed") {
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas)
        drawDashArray = [5,10]
        drawWidth = 6
        drawPreview()
      }
      else if (event.target.value == "square") {
        drawDashArray = []
        drawWidth = 20
        drawPreview()
      }
      else if (event.target.value == "circle") {
        drawDashArray = []
        drawWidth = 20
        drawPreview()
      }
      else if (event.target.value == "seed") {
        drawDashArray = []
        drawWidth = 20
        drawPreview()
      }

    })

    document.getElementById('feature-draw-color').addEventListener('change', (event) => {
      drawColor = event.target.value
      canvas.clear()
      drawPreview({})
    })

    // Icon
    ///////

    var fill_color = document.getElementById("feature-icon-color-fill").value
    var outline_color = document.getElementById("feature-icon-color-outline").value

    document.getElementById('feature-square').onclick = function () {
      var square = new fabric.Rect({
        width: 50,
        height: 50,
        stroke: outline_color,
        fill: fill_color
      });
      canvas.add(square)
      canvas.centerObject(square)
    }
    document.getElementById('feature-circle').onclick = function () {
      var circle = new fabric.Circle({
        radius: 25,
        stroke: outline_color,
        fill: fill_color
      });
      canvas.add(circle)
      canvas.centerObject(circle)
    }
    document.getElementById('feature-triangle').onclick = function () {
      var triangle = new fabric.Triangle({
        width: 50,
        height: 50,
        stroke: outline_color,
        fill: fill_color
      });
      canvas.add(triangle)
      canvas.centerObject(triangle)
    }
    document.getElementById('feature-water').onclick = function () {
      fabric.loadSVGFromURL('https://erxstorage.s3.us-west-1.amazonaws.com/images/water-solid.svg', function(objects, options) {
        var obj = fabric.util.groupSVGElements(objects, options);
        obj.scaleToWidth(50)
        obj.scaleToHeight(50)
        obj.set("fill", fill_color)
        canvas.add(obj)
        canvas.centerObject(obj)
      }, null, {crossOrigin: 'anonymous'});
    }
    document.getElementById('feature-mixer').onclick = function () {
      fabric.loadSVGFromURL('https://erxstorage.s3.us-west-1.amazonaws.com/images/concrete_mixer.svg', function(objects, options) {
        var group = fabric.util.groupSVGElements(objects, options);
        group.scaleToWidth(50)
        group.scaleToHeight(50)
        group._objects.forEach(function(obj){
          obj.set("fill", fill_color)
        })
        canvas.add(group)
        canvas.centerObject(group)
      });
    }
    document.getElementById('feature-arrow').onclick = function () {
      // http://www.independent-software.com/loading-an-svg-image-with-fabric-js.html
      fabric.loadSVGFromURL('https://erxstorage.s3.us-west-1.amazonaws.com/images/north-arrow-2.svg', function(objects, options) {
        var obj = fabric.util.groupSVGElements(objects, options)
        obj.scaleToWidth(50)
        obj.scaleToHeight(50)
        obj.set("fill", fill_color)
        canvas.add(obj)
        canvas.centerObject(obj)
      });
    }
    document.getElementById('feature-ban').onclick = function () {
      // http://www.independent-software.com/loading-an-svg-image-with-fabric-js.html
      fabric.loadSVGFromURL('https://erxstorage.s3.us-west-1.amazonaws.com/images/ban-solid.svg', function(objects, options) {
        var obj = fabric.util.groupSVGElements(objects, options)
        obj.scaleToWidth(50)
        obj.scaleToHeight(50)
        obj.set("fill", fill_color)
        canvas.add(obj)
        canvas.centerObject(obj)
      });
    }
    document.getElementById('feature-star').onclick = function () {
      // http://www.independent-software.com/loading-an-svg-image-with-fabric-js.html
      // fabric.loadSVGFromURL('https://erxstorage.s3.us-west-1.amazonaws.com/images/map_legend/Five_Pointed_Star_Solid.svg', function(objects, options) {
      //   var obj = fabric.util.groupSVGElements(objects, options)
      //   obj.scaleToWidth(50)
      //   obj.scaleToHeight(50)
      //   obj.set("fill", fill_color)
      //   canvas.add(obj)
      //   canvas.centerObject(obj)
      // });
      var points=starPolygonPoints(5,50,25)
      var myPoly = new fabric.Polygon(points, {
          stroke: outline_color,
          fill: fill_color,
          strokeWidth: 2,
          strokeLineJoin: 'bevil'
      },false)
      canvas.add(myPoly)
      canvas.centerObject(myPoly)
    }
    document.getElementById('feature-hex').onclick = function () {
      var points=regularPolygonPoints(6,30)
      var myPoly = new fabric.Polygon(points, {
          stroke: outline_color,
          fill: fill_color,
          strokeWidth: 2,
          strokeLineJoin: 'bevil'
      },false)
      canvas.add(myPoly)
      canvas.centerObject(myPoly)
    }
    document.getElementById('feature-textbox').onclick = function () {
      var textbox = new fabric.Textbox('Enter text...',{
        width: 50,
        fontSize: 12,
        stoke: null
      });
      canvas.add(textbox)
      canvas.centerObject(textbox)
    };
    document.getElementById('feature-line').onclick = function () {
      var line = new fabric.Line([10,10, 50,50],{
        top: 10,
        left: 10,
        stroke: outline_color
      });
      canvas.add(line)
    };

    document.getElementById('bring-forward').onclick = function () {
      canvas.getActiveObject().bringForward(this, true);
    };

    document.getElementById('send-backward').onclick = function () {
      canvas.getActiveObject().sendBackwards(this, true);
    };

    document.getElementById('feature-icon-color-fill').addEventListener('change', (event) => {
      fill_color = event.target.value
      if ( canvas.getActiveObject() ) {
        if ( canvas.getActiveObject().type == "group" ) {
          canvas.getActiveObject()._objects.forEach(function(obj){
            obj.set("fill", fill_color)
          })
        } else {
          canvas.getActiveObject().set("fill", event.target.value)
        }
      }
      canvas.renderAll();
    })
    document.getElementById('feature-icon-color-outline').addEventListener('change', (event) => {
      outline_color = event.target.value

      if (canvas.getActiveObject()) {
        canvas.getActiveObject().set("stroke", event.target.value)
      }
      canvas.renderAll();
    })
    document.getElementById('delete-feature').onclick = function() {
      obj = canvas.getActiveObject()
      canvas.remove(obj)
    };
    document.getElementById('feature-image-upload').onchange = function handleImage(e) {
      var reader = new FileReader();
      reader.onload = function (event){
        var imgObj = new Image();
        imgObj.src = event.target.result;
        imgObj.onload = function () {
          var image = new fabric.Image(imgObj);
          image.scaleToWidth(100)
          canvas.centerObject(image);
          canvas.add(image);
          canvas.renderAll();
        }
      }
      reader.readAsDataURL(e.target.files[0]);
    }

  }
})
