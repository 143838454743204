$(document).on('turbolinks:load', function() {

	if ( document.querySelector('body.lot_stabilization_logs') ) {
			$("select[id$='_deficiency']").editableSelect();
	}

	if ( document.querySelector('body.lot_stabilization_logs.show') ) {

		// https://stackoverflow.com/questions/36217098/checkboxes-only-work-on-first-page-datatables-rails
    var table = $('#dtLotStabilizationLog').DataTable({
			dom: '<"d-flex justify-content-between"fBl>rtip',
	    buttons: [
	      'pageLength', 'copy', 'csv', 'excel',
	      {
	        extend: 'print',
	        exportOptions: {
	          columns: [0,1,2,3,4,5]
	        }
	      },
	      {
	        extend: 'pdf',
	        exportOptions: {
	          columns: [0,1,2,3,4,5]
	        }
	      }
	    ],
	    order : [
	      [1, "asc"]
	    ],
		columnDefs: [
			{
				targets: 0,
				checkboxes: {
					selectRow: true
				}
			}
		],
		select: {
			style: "multi"
		}
		});

		// Handle form submission event
		$('form').on('submit', function(e){
			var form = this;
  
			var rows_selected = table.column(0).checkboxes.selected();
  
			// Iterate over all selected checkboxes
			$.each(rows_selected, function(index, rowId){
			   // Create a hidden element
			   $(form).append(
				   $('<input>')
					  .attr('type', 'hidden')
					  .attr('name', 'ids[]')
					  .val(rowId)
			   );
			});
		  });
	};

});
