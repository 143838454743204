$(document).on("turbolinks:load", function (){

  if ( document.querySelector('body.maps.show') && document.getElementById('map-show-static-canvas') ) {
    var mapShowStaticCanvas = new fabric.StaticCanvas('map-show-static-canvas');
    window.addEventListener('resize', resizeStaticCanvas);

    var latest_map = document.getElementById('map_revision_latest_canvas_string').value
    mapShowStaticCanvas.loadFromJSON(latest_map, function() {
      mapShowStaticCanvas.renderAll.bind(mapShowStaticCanvas);
      resizeStaticCanvas();
    });

    function resizeStaticCanvas() {
      // jsfiddle.net/wq3pyhhu/8/
      var canvasSizer = document.getElementById("canvas-row");
      var width = canvasSizer.offsetWidth;
      var ratio = mapShowStaticCanvas.backgroundImage.width / mapShowStaticCanvas.backgroundImage.height;
      var scale = mapShowStaticCanvas.backgroundImage.scaleX;
      var height = width / ratio
      var zoom = (width / mapShowStaticCanvas.backgroundImage.width) / scale;
      mapShowStaticCanvas.setDimensions({ width: width, height: height });
      mapShowStaticCanvas.setViewportTransform([zoom , 0, 0, zoom , 0, 0])
    };

    // var imageLink = document.getElementById("map-show-image-link");
    // imageLink.addEventListener("click", function() {
    //   var dataURL = mapShowStaticCanvas.toDataURL({
    //     multiplier: 3,
    //     format: 'jpeg',
    //     quality: 1
    //   });
    //   // https://stackoverflow.com/questions/5403709/convert-canvas-to-image-and-open-in-new-window-using-ruby-on-rails-and-javascrip
    //   // open().document.write('<img src="'+dataURL+'"/>');
    //   open().document.body.innerHTML = '<img src="'+dataURL+'"/>'
    // });

  };
});
