$(document).on("turbolinks:load", function() {
  // https://forum.jquery.com/topic/jquery-slider-for-date-range-in-two-text-boxes
  // var minDate = new Date(2010, 8-1, 1);
  // var maxDate = new Date(2010, 8-1, 31);
  // var slider;
  // var startDate;
  // var endDate;
  // $(function() {
  //     slider = $('#slider').slider({
  //       range: true,
  //       max: daysDiff(minDate, maxDate),
  //       slide: function(event, ui) {
  //         resync(ui.values);
  //       }
  //     });
  //     startDate = $('#startDate').datepicker({
  //       minDate: minDate,
  //       maxDate: maxDate,
  //       onSelect: function(dateStr) { resync(); }}). keyup(function() { resync(); });
  //     endDate = $('#endDate').datepicker({
  //       minDate: minDate,
  //       maxDate: maxDate,
  //       onSelect: function(dateStr) { resync(); }}). keyup(function() { resync(); });
  // });
  //
  // function resync(values) {
  //     if (values) {
  //         var date = new Date(minDate.getTime());
  //         date.setDate(date.getDate() + values[0]);
  //         startDate.val($.datepicker.formatDate('mm/dd/yy', date));
  //         date = new Date(minDate.getTime());
  //         date.setDate(date.getDate() + values[1]);
  //         endDate.val($.datepicker.formatDate('mm/dd/yy', date));
  //     }
  //     else {
  //         var start = daysDiff(minDate, startDate.datepicker('getDate') || minDate);
  //         var end = daysDiff(minDate, endDate.datepicker('getDate') || maxDate);
  //         start = Math.min(start, end);
  //         slider.slider('values', 0, start);
  //         slider.slider('values', 1, end);
  //     }
  //     startDate.datepicker('option', 'maxDate', endDate.datepicker('getDate') || maxDate);
  //     endDate.datepicker('option', 'minDate', startDate.datepicker('getDate') || minDate);
  // }
  //
  // function daysDiff(d1, d2) {
  //     return  Math.floor((d2.getTime() - d1.getTime()) / 86400000);
  // }

  $('#dtRainEvents').DataTable({
    dom: 'PBfrtip',
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [2,4,5,6],
      layout: 'columns-4'
    },
    buttons: [
      'pageLength'
    ],
    lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
    order : [
    ]
  });

});
