$(document).on('turbolinks:load', function() {
    console.log("TEST 1")
        function updateTemplateSelect(element, selected_value) {
            console.log("UpdateTemplateSelect")
            var inspection_type = $(element).val();
            $.ajax({
                url: "/template_inspection_reports",
                method: "GET",
                dataType: "json",
                data: {inspection_type: inspection_type},
                error: function (xhr, status, error) {
                    console.error('AJAX Error: ' + status + error);
                },
                success: function (response) {
                    var templates = response["templates"];
                    var report_template_select = $(element).closest('.nested-fields').find("select[id$='template_inspection_report_id']")
                    report_template_select.empty();
    
                    for(var i=0; i< templates.length; i++){
                        report_template_select.append('<option value="' + templates[i]["id"] + '">' + templates[i]["name"] + '</option>');
                    }
    
                    if(inspection_type == "SWPP" && !selected_value){
                        report_template_select.find('option[value=' + '131' + ']').prop('selected', true);
                    }
    
                    if (selected_value) {
                        report_template_select.find('option[value=' + selected_value + ']').prop('selected', true)
                    };
    
                    toggleReportTemplate( report_template_select.closest('.nested-fields') );
    
                }
            });
        };
    
        function toggleRainThreshold() {
            var requirement = $('form').find('select[id="site_regulatory_profile_attributes_rain_inspection_requirement"]')
            var threshold = $('form').find('input[id="site_regulatory_profile_attributes_rain_event_threshold"]')
            console.log(requirement)
            if ( requirement.val() == "Not Required" ) {
                threshold.val( 0 )
                requirement.closest('.form-group').next('.form-group').hide()
            } else {
                requirement.closest('.form-group').next('.form-group').show()
            }
        }
    
        function toggleMapType() {
            var mapType = $('form').find('select[id="site_satellite_map"]')
            console.log(mapType.closest('.form-group').next('.form-group'))
            console.log(mapType.closest('.form-group'))
        }
    
        function toggleReportTemplate(element) {
            var report_template_select = $(element).find("select[id$='template_inspection_report_id']")
            var upload_option = $(element).find("input[id$='upload_only']")
            if (upload_option[0].checked) {
                report_template_select.val("")
                report_template_select.prop("disabled", true)
            } else {
                report_template_select.prop("disabled", false)
                // report_template_select.prop("selectedIndex", 0)
            }
        }
    
        $('#site_regulatory_profile_attributes_rain_inspection_requirement').on("change", function() {
            toggleRainThreshold();
        });
    
        if ( document.querySelector('body.sites.new, body.sites.create, body.sites.edit, body.sites.inspection_settings') ) {
    
            // Update all existing profiles
            $(document).find("select[id$='name']").each(function(i){
                var report_template_select = $(this).closest('.nested-fields').find("select[id$='template_inspection_report_id']")
                updateTemplateSelect( $(this), report_template_select.val() );
            });
    
            $('#inspection-profile-section').on("change", function(e){
                // Only update the select box if it's the Inspection Type field was changed.
                if ( e.target.id.endsWith("name") ) {
                    updateTemplateSelect( e.target )
                }
                if ( e.target.id.endsWith("upload_only") ) {
                    toggleReportTemplate( $(e.target).closest('.nested-fields') );
                }
            });
    
            $('#site_satellite_map').on("change", function(e){
                console.log( e )
            });
    
            $('.simple_form').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
                updateTemplateSelect( $(insertedItem).find("select[id$='name']") )
                toggleReportTemplate(insertedItem);
            });
    
            toggleRainThreshold();
    
            $(document).find("input[id$='upload_only']").each(function(i){
                toggleReportTemplate( $(this).closest('.nested-fields') );
            });
    
    
        };
    
        if ( document.querySelector('body.sites.logs_report') ) {
            document.getElementById("site_log_report_form").addEventListener('submit', event => {
                $('#site_log_report_pdf').hide()
            });
        }
    
        if ( document.querySelector('body.sites.deficiencies') ) {
            const event = new Event('resize');
            const newColor = "#FFFF00"
            const oldColor = "#ff0000"
    
            if (!document.getElementById("uploadmap").dataset.basemapid){
    
                console.log(document.getElementById("uploadmap").dataset.basemapid)
                console.log(!document.getElementById("uploadmap").dataset.basemapid)
    
                document.getElementsByClassName("map")[0].id = Date.now()
                const map_container = document.getElementsByClassName("map")[0]
                const map = new mapboxgl.Map({
                    container: map_container.id, // container ID
                    style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
                    center: [map_container.dataset.longitude, map_container.dataset.latitude], // starting position [lng, lat]
                    zoom: 16, // starting zoom
                    preserveDrawingBuffer: true
                });    

                map.addControl(new mapboxgl.NavigationControl());
    
                const bounds = map.getBounds().toArray();
                var coordinatesCollection = [];
                
                map.on('load', function() {
    
                    jQuery.ajax({
                        url: document.getElementsByClassName("map")[0].dataset.otheractionitemsurl,
                        type: "GET",
                        dataType: 'JSON',
                        success: function (data, textStatus, jqXHR ){
                            //console.log(data);
                        },
                        complete: function ( data ) {
                            coordinatesCollection.push(bounds[0]);
                            coordinatesCollection.push(bounds[1]);

                            console.log(data)
    
                            //console.log(data);
    
                            data.responseJSON.forEach( function(swpp_corrective_action){
                                if(swpp_corrective_action.feature_string){
                                    var jsonObj = JSON.parse(swpp_corrective_action.feature_string)
                                } else {
                                    var jsonObj = false
                                }
                                if(jsonObj && converter(swpp_corrective_action.deficiency_map_id) == map_container.dataset.basemapid){
                                    jsonObj.features.forEach ( function(feature) {
                                        //console.log(feature.geometry)
                                        feature.properties.description = swpp_corrective_action.ref_id
                                        map.addSource(`${feature.id}`, {
                                            'type': 'geojson',
                                            'data': feature
                                        });
                                        // Add layer for drawn objects (polygons, points, etc)
                                        var type, paint_opts, layout_opts
                                        switch(feature.geometry.type) {
                                            case 'Polygon':
                                                map.addLayer({
                                                    'id': `${feature.id} fill`,
                                                    'type': 'fill',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'fill-color': oldColor,
                                                        'fill-outline-color': oldColor,
                                                        'fill-opacity': 0.1
                                                    }
                                                })
                                                map.addLayer({
                                                    'id': `${feature.id} outline`,
                                                    'type': 'line',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'line-color': oldColor,
                                                        'line-width': 2
                                                    }
                                                })
                                                break;
                                            case 'Point':
                                                map.addLayer({
                                                    'id': `${feature.id} circle`,
                                                    'type': 'circle',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'circle-color': oldColor,
                                                        'circle-radius': 5,
                                                    }
                                                })
                                                break;
                                            case 'LineString':
                                                map.addLayer({
                                                    'id': `${feature.id} line`,
                                                    'type': 'line',
                                                    'source': feature.id,
                                                    'layout': {
                                                        'line-cap': 'round',
                                                        'line-join': 'round'
                                                    },
                                                    'paint': {
                                                        'line-color': oldColor,
                                                        'line-width': 2
                                                    }
                                                })
                                                break;
                                            default:
                                        }
                                        // Add layer for Ref ID labels
                                        map.addLayer({
                                            'id': feature.id,
                                            'type': 'symbol',
                                            'source': `${feature.id}`,
                                            'layout': {
                                                'text-field': ['get', 'description'],
                                                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                                'text-radial-offset': 0.5,
                                                'text-justify': 'auto',
                                                'text-allow-overlap': false,
                                                'text-ignore-placement': false,
                                                'icon-allow-overlap': false,
                                                'icon-ignore-placement': false,
                                                'icon-image': ['get', 'icon']
                                            },
                                            'paint': {
                                                'text-color': oldColor,
                                                'text-halo-color': '#000000',
                                                'text-halo-width': 3
                                            }
                                        });
    
                                        if (!feature.geometry.coordinates[0][0]){
                                            //console.log("First condition")
                                            coordinatesCollection.push(feature.geometry.coordinates);
                                            //console.log(feature.geometry.coordinates);
                                        } else if(!feature.geometry.coordinates[0][0][0]) {
                                            //console.log("Second condition");
                                            for(let i = 0; i < feature.geometry.coordinates.length; i++){
                                                coordinatesCollection.push(feature.geometry.coordinates[i]);
                                                //console.log(feature.geometry.coordinates[i]);
                                            }
                                        } else {
                                            for(let i = 0; i < feature.geometry.coordinates.length; i++){
                                                for(let j = 0; j < feature.geometry.coordinates[i].length; j++){
                                                    coordinatesCollection.push(feature.geometry.coordinates[i][j]);
                                                    //console.log(feature.geometry.coordinates[i][j]);
                                                }
                                            }
                                        }
                                    })
                                }
                                                        
                            })
            
                            const lowestLng = Math.min(
                                ...coordinatesCollection.map((coordinates) => coordinates[0])
                            );
                            const lowestLat = Math.min(
                                ...coordinatesCollection.map((coordinates) => coordinates[1])
                            );
                            const highestLng = Math.max(
                                ...coordinatesCollection.map((coordinates) => coordinates[0])
                            );
                            const highestLat = Math.max(
                                ...coordinatesCollection.map((coordinates) => coordinates[1])
                            );
    
                            map.fitBounds([[lowestLng, lowestLat], [highestLng, highestLat]], {
                                padding: 30,
                                maxZoom: 18
                            });             
                        }
                    })
    
    
                    jQuery.ajax({
                        url: document.getElementById("uploadmap").dataset.url,
                        type: "GET",
                        dataType: 'JSON',
                        success: function (data, textStatus, jqXHR ){
                            //console.log(data);
                        },
                        complete: function ( data ) {
                            coordinatesCollection.push(bounds[0]);
                            coordinatesCollection.push(bounds[1]);
    
                            console.log(data);
    
                            data.responseJSON.swpp_corrective_actions.forEach( function(swpp_corrective_action){
                                if(swpp_corrective_action.feature_string){
                                    var jsonObj = JSON.parse(swpp_corrective_action.feature_string)
                                } else {
                                    var jsonObj = false
                                }
                                if(jsonObj && converter(swpp_corrective_action.deficiency_map_id) == map_container.dataset.basemapid){
                                    jsonObj.features.forEach ( function(feature) {
                                        //console.log(feature.geometry)
                                        feature.properties.description = swpp_corrective_action.ref_id
                                        map.addSource(`${feature.id}`, {
                                            'type': 'geojson',
                                            'data': feature
                                        });
                                        // Add layer for drawn objects (polygons, points, etc)
                                        var type, paint_opts, layout_opts
                                        switch(feature.geometry.type) {
                                            case 'Polygon':
                                                map.addLayer({
                                                    'id': `${feature.id} fill`,
                                                    'type': 'fill',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'fill-color': newColor,
                                                        'fill-outline-color': newColor,
                                                        'fill-opacity': 0.1
                                                    }
                                                })
                                                //map.moveLayer('backgroundlayer', `${feature.id} fill`)
                                                //console.log(`${feature.id} fill`)
                                                map.addLayer({
                                                    'id': `${feature.id} outline`,
                                                    'type': 'line',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'line-color': newColor,
                                                        'line-width': 2
                                                    }
                                                })
                                                //map.moveLayer('backgroundlayer', `${feature.id} outline`)
                                                //console.log(`${feature.id} outline`)
                                                break;
                                            case 'Point':
                                                map.addLayer({
                                                    'id': `${feature.id} circle`,
                                                    'type': 'circle',
                                                    'source': feature.id,
                                                    'paint': {
                                                        'circle-color': newColor,
                                                        'circle-radius': 5,
                                                    }
                                                })
                                                //map.moveLayer('backgroundlayer', `${feature.id} circle`)
                                                //console.log(`${feature.id} circle`)
                                                break;
                                            case 'LineString':
                                                map.addLayer({
                                                    'id': `${feature.id} line`,
                                                    'type': 'line',
                                                    'source': feature.id,
                                                    'layout': {
                                                        'line-cap': 'round',
                                                        'line-join': 'round'
                                                    },
                                                    'paint': {
                                                        'line-color': newColor,
                                                        'line-width': 2
                                                    }
                                                })
                                                //map.moveLayer('backgroundlayer', `${feature.id} line`)
                                                //console.log(`${feature.id} line`)
                                                break;
                                            default:
                                        }
                                        // Add layer for Ref ID labels
                                        map.addLayer({
                                            'id': feature.id,
                                            'type': 'symbol',
                                            'source': `${feature.id}`,
                                            'layout': {
                                                'text-field': ['get', 'description'],
                                                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                                'text-radial-offset': 0.5,
                                                'text-justify': 'auto',
                                                'text-allow-overlap': false,
                                                'text-ignore-placement': false,
                                                'icon-allow-overlap': false,
                                                'icon-ignore-placement': false,
                                                'icon-image': ['get', 'icon']
                                            },
                                            'paint': {
                                                'text-color': newColor,
                                                'text-halo-color': '#000000',
                                                'text-halo-width': 3
                                            }
                                        });
                                        //map.moveLayer(feature.id, 'backgroundlayer')
                                        //console.log(feature.id)
                
                                        if (!feature.geometry.coordinates[0][0]){
                                            //console.log("First condition")
                                            coordinatesCollection.push(feature.geometry.coordinates);
                                            //console.log(feature.geometry.coordinates);
                                        } else if(!feature.geometry.coordinates[0][0][0]) {
                                            //console.log("Second condition");
                                            for(let i = 0; i < feature.geometry.coordinates.length; i++){
                                                coordinatesCollection.push(feature.geometry.coordinates[i]);
                                                //console.log(feature.geometry.coordinates[i]);
                                            }
                                        } else {
                                            for(let i = 0; i < feature.geometry.coordinates.length; i++){
                                                for(let j = 0; j < feature.geometry.coordinates[i].length; j++){
                                                    coordinatesCollection.push(feature.geometry.coordinates[i][j]);
                                                    //console.log(feature.geometry.coordinates[i][j]);
                                                }
                                            }
                                        }
                                    })
                                    
                                }                            
                            })
                                
            
                            const lowestLng = Math.min(
                                ...coordinatesCollection.map((coordinates) => coordinates[0])
                            );
                            const lowestLat = Math.min(
                                ...coordinatesCollection.map((coordinates) => coordinates[1])
                            );
                            const highestLng = Math.max(
                                ...coordinatesCollection.map((coordinates) => coordinates[0])
                            );
                            const highestLat = Math.max(
                                ...coordinatesCollection.map((coordinates) => coordinates[1])
                            );
    
                            map.fitBounds([[lowestLng, lowestLat], [highestLng, highestLat]], {
                                padding: 30,
                                maxZoom: 18
                            });
    
    
            
                                            
                        }
            
                    })
    
                    
    
                })
                
            } else {
                console.log("body.swpp_inspections.show and not sat map");
                //console.log(document.getElementsByClassName("map")[0].dataset.satelliteMap);
                //console.log(document.getElementsByClassName("map")[0].dataset.basemapurl);
                var i = 0;
                var j = 1;
                var layersCheck = "";
                var oldScaCount = -1;
                var k = 0;
                var newScaCount = -1;
                var l = 0;
                var lowestLat = 0;
                var lowestLng = 0;
                var highestLat = 0;
                var highestLng = 0;
                var dx = 0;
                var dy = 0;
    
                //console.log(document.getElementsByClassName("map")[0].dataset.satelliteMap)
    
                document.getElementsByClassName("map")[0].id = Date.now()
                const map_container = document.getElementsByClassName("map")[0]
                const map = new mapboxgl.Map({
                    container: map_container.id, // container ID
                    style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
                    center: [map_container.dataset.longitude, map_container.dataset.latitude], // starting position [lng, lat]
                    zoom: 16, // starting zoom
                    preserveDrawingBuffer: true
                });   

                map.addControl(new mapboxgl.NavigationControl());
    
                const bounds = map.getBounds().toArray();
                const center = [map_container.dataset.longitude, map_container.dataset.latitude];
                var coordinatesCollection = [];
                
                map.on('load', function() {    
                    const getMeta = (url, cb) => {
                        const img = new Image();
                        img.onload = () => cb(null, img);
                        img.onerror = (err) => cb(err);
                        img.src = url;
                    };

                    const mapPixelCenter = map.project(map.getCenter());
                    
                    getMeta(document.getElementsByClassName("map")[0].dataset.basemapurl + '?' + Math.random().toString().split('.').join(""), (err, img) => {
                        const lowestLatOld = Number(map_container.dataset.latitude) + img.naturalHeight/500000;
                        const lowestLngOld = Number(map_container.dataset.longitude) - img.naturalWidth/500000;
                        const highestLatOld = Number(map_container.dataset.latitude) - img.naturalHeight/500000;
                        const highestLngOld = Number(map_container.dataset.longitude) + img.naturalWidth/500000;

                        const lowestLatPixel = Number(mapPixelCenter.y) - Number(img.naturalHeight)/10;
                        const lowestLngPixel = Number(mapPixelCenter.x) - Number(img.naturalWidth)/10;
                        const highestLatPixel = Number(mapPixelCenter.y) + Number(img.naturalHeight)/10;
                        const highestLngPixel = Number(mapPixelCenter.x) + Number(img.naturalWidth)/10;

                        const lowestLatNew = Number(map.unproject([lowestLngPixel, lowestLatPixel]).lat);
                        const lowestLngNew = Number(map.unproject([lowestLngPixel, lowestLatPixel]).lng);
                        const highestLatNew = Number(map.unproject([highestLngPixel, highestLatPixel]).lat);
                        const highestLngNew = Number(map.unproject([highestLngPixel, highestLatPixel]).lng);

                        dx = (lowestLngNew - Number(map_container.dataset.longitude))/(lowestLngOld - Number(map_container.dataset.longitude))
                        dy = (lowestLatNew - Number(map_container.dataset.latitude))/(lowestLatOld - Number(map_container.dataset.latitude))

                        lowestLat = lowestLatNew;
                        lowestLng = lowestLngNew;
                        highestLat = highestLatNew;
                        highestLng = highestLngNew;
                        height = Number(img.naturalHeight);
                        width = Number(img.naturalWidth);
                    });
    
                    map.on('idle', function(){
                        if(i == 0 && highestLng == 0){
                            setTimeout(function(){
                                window.dispatchEvent(event);
                            }, 250)
    
                        }
                        if(i == 0 && highestLng != 0){
                            console.log("1. Loading image.")
                            map.loadImage(
                                document.getElementsByClassName("map")[0].dataset.basemapurl + '?' + Math.random().toString().split('.').join(""),
                                (error, image) => {
                                    if (error) throw error;
    
                                    console.log("Highest Lng is:");
                                    console.log(highestLng);
                                    
                
                                    map.addSource('radar', {
                                        'type': 'image',
                                        'url': map_container.dataset.basemapurl + '?' + Math.random().toString().split('.').join(""),
                                        'coordinates': [
                                                [lowestLng, lowestLat],
                                                [highestLng, lowestLat],
                                                [highestLng, highestLat],
                                                [lowestLng, highestLat]
                                            ]
                                        });
                                        map.addLayer({
                                            id: 'image',
                                            'type': 'raster',
                                            'source': 'radar',
                                            'paint': {
                                                'raster-fade-duration': 0
                                            }
                                        });
                                    
                                }
                            );  
                            //console.log(document.getElementsByClassName("map")[0].dataset.basemapurl)
                            window.dispatchEvent(event);
                            i = 1;
                        }
                        if(i == 1 && map.getLayer("image")){
    
                            console.log("Image loaded!")
                            console.log("2. Fitting map bounds.")
    
                            map.fitBounds([[Number(lowestLng), Number(lowestLat)], [Number(highestLng), Number(highestLat)]], {
                                padding: 0
                                //maxZoom: 18
                            });
    
                            i = 2;
    
                            window.dispatchEvent(event);
                        }
                        if(i == 2 && map.getLayer("image")){
                            console.log("3. Making unneeded layers invisible.")
                            
                            map.style.stylesheet.layers.forEach(function(layer) {
                                if(layer.id != "image"){
                                    map.setLayoutProperty(layer.id,"visibility", "none");
                                    j++;
                                    if(j == map.style.stylesheet.layers.length){
                                        layersCheck = "Done";
                                    }
                                } 
                            });
    
                            i = 3;
    
                            window.dispatchEvent(event);
                        }
                        if(i == 3 && layersCheck == "Done"){
                            console.log("4. Adding corrective actions from previous inspections.")
                            
                            jQuery.ajax({
                                url: document.getElementsByClassName("map")[0].dataset.otheractionitemsurl,
                                type: "GET",
                                dataType: 'JSON',
                                success: function (data, textStatus, jqXHR ){
                                    //console.log(data);
                                },
                                complete: function ( data ) {
                                    oldScaCount = data.responseJSON.length * 2;
                                    // console.log(data);
                                    // console.log(data.responseJSON);
                                    data.responseJSON.forEach( function(swpp_corrective_action){
                                        if(swpp_corrective_action.feature_string){
                                            var jsonObj = JSON.parse(swpp_corrective_action.feature_string)
                                        } else {
                                            var jsonObj = false
                                        }
                                        if(jsonObj && converter(swpp_corrective_action.deficiency_map_id) == map_container.dataset.basemapid){
                                            if (swpp_corrective_action.old_coordinates){
                                                transformCoordinates(jsonObj, center, dx, dy);
                                                console.log("Value for old_coordinates:")
                                                console.log(swpp_corrective_action.old_coordinates);
                                            }
                                            jsonObj.features.forEach ( function(feature) {
                                                feature.properties.description = swpp_corrective_action.ref_id
                                                map.addSource(`${feature.id}`, {
                                                    'type': 'geojson',
                                                    'data': feature
                                                });
                                                // Add layer for drawn objects (polygons, points, etc)
                                                var type, paint_opts, layout_opts
                                                switch(feature.geometry.type) {
                                                    case 'Polygon':
                                                        map.addLayer({
                                                            'id': `${feature.id} fill`,
                                                            'type': 'fill',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'fill-color': oldColor,
                                                                'fill-outline-color': oldColor,
                                                                'fill-opacity': 0.1
                                                            }
                                                        })
                                                        map.addLayer({
                                                            'id': `${feature.id} outline`,
                                                            'type': 'line',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'line-color': oldColor,
                                                                'line-width': 2
                                                            }
                                                        })
                                                        layerId = `${feature.id} fill`;
                                                        k++;
                                                        break;
                                                    case 'Point':
                                                        map.addLayer({
                                                            'id': `${feature.id} circle`,
                                                            'type': 'circle',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'circle-color': oldColor,
                                                                'circle-radius': 5,
                                                            }
                                                        })
                                                        layerId = `${feature.id} circle`;
                                                        k++
                                                        break;
                                                    case 'LineString':
                                                        map.addLayer({
                                                            'id': `${feature.id} line`,
                                                            'type': 'line',
                                                            'source': feature.id,
                                                            'layout': {
                                                                'line-cap': 'round',
                                                                'line-join': 'round'
                                                            },
                                                            'paint': {
                                                                'line-color': oldColor,
                                                                'line-width': 2
                                                            }
                                                        })
                                                        layerId = `${feature.id} line`;
                                                        k++
                                                        break;
                                                    default:
                                                }
                                                
                                                // Add layer for Ref ID labels
                                                map.addLayer({
                                                    'id': feature.id,
                                                    'type': 'symbol',
                                                    'source': `${feature.id}`,
                                                    'layout': {
                                                        'text-field': ['get', 'description'],
                                                        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                                        'text-radial-offset': 0.5,
                                                        'text-justify': 'auto',
                                                        'text-allow-overlap': false,
                                                        'text-ignore-placement': false,
                                                        'icon-allow-overlap': false,
                                                        'icon-ignore-placement': false,
                                                        'icon-image': ['get', 'uploadmap']
                                                    },
                                                    'paint': {
                                                        'text-color': oldColor,
                                                        'text-halo-color': '#000000',
                                                        'text-halo-width': 3
                                                    }
                                                });
                                                k++
            
                                                
                                            })
                                        }
                                                                
                                    })
                                                    
                                }
                    
                            })
    
                            i = 4;
    
                            window.dispatchEvent(event);
    
                        }
                        if(i == 4){
                            console.log("5. Adding corrective actions from previous inspection.")
                            
                            jQuery.ajax({
                                url: document.getElementsByClassName("map")[0].dataset.url,
                                type: "GET",
                                dataType: 'JSON',
                                success: function (data, textStatus, jqXHR ){
                                    //console.log(data);
                                },
                                complete: function ( data ) {
            
                                    newScaCount = data.responseJSON.swpp_corrective_actions.length * 2;
                                    
                                    data.responseJSON.swpp_corrective_actions.forEach( function(swpp_corrective_action){
                                        if(swpp_corrective_action.feature_string){
                                            var jsonObj = JSON.parse(swpp_corrective_action.feature_string)
                                        } else {
                                            var jsonObj = false
                                        }
                                        if(jsonObj && converter(swpp_corrective_action.deficiency_map_id) == map_container.dataset.basemapid){
                                            jsonObj.features.forEach ( function(feature) {
                                                //console.log(feature.geometry)
                                                feature.properties.description = swpp_corrective_action.ref_id
                                                map.addSource(`${feature.id}`, {
                                                    'type': 'geojson',
                                                    'data': feature
                                                });
                                                // Add layer for drawn objects (polygons, points, etc)
                                                var type, paint_opts, layout_opts
                                                switch(feature.geometry.type) {
                                                    case 'Polygon':
                                                        map.addLayer({
                                                            'id': `${feature.id} fill`,
                                                            'type': 'fill',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'fill-color': newColor,
                                                                'fill-outline-color': newColor,
                                                                'fill-opacity': 0.1
                                                            },
                                                        })
                                                        map.addLayer({
                                                            'id': `${feature.id} outline`,
                                                            'type': 'line',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'line-color': newColor,
                                                                'line-width': 2
                                                            }
                                                        })
                                                        //map.moveLayer('backgroundlayer', `${feature.id} outline`)
                                                        layerId = `${feature.id} fill`;
                                                        l++
                                                        break;
                                                    case 'Point':
                                                        map.addLayer({
                                                            'id': `${feature.id} circle`,
                                                            'type': 'circle',
                                                            'source': feature.id,
                                                            'paint': {
                                                                'circle-color': newColor,
                                                                'circle-radius': 5
                                                            }
                                                        })
                                                        l++;
                                                        layerId = `${feature.id} circle`;
                                                        break;
                                                    case 'LineString':
                                                        map.addLayer({
                                                            'id': `${feature.id} line`,
                                                            'type': 'line',
                                                            'source': feature.id,
                                                            'background-color': 'transparent',
                                                            'background-opacity': 0,
                                                            'layout': {
                                                                'line-cap': 'round',
                                                                'line-join': 'round'
                                                            },
                                                            'paint': {
                                                                'line-color': newColor,
                                                                'line-width': 2
                                                            }
                                                        })
                                                        l++;
                                                        layerId = `${feature.id} line`;
                                                        break;
                                                    default:
                                                }
                                                // Add layer for Ref ID labels
                                                map.addLayer({
                                                    'id': feature.id,
                                                    'type': 'symbol',
                                                    'source': `${feature.id}`,
                                                    'layout': {
                                                        'text-field': ['get', 'description'],
                                                        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                                        'text-radial-offset': 0.5,
                                                        'text-justify': 'auto',
                                                        'text-allow-overlap': false,
                                                        'text-ignore-placement': false,
                                                        'icon-allow-overlap': false,
                                                        'icon-ignore-placement': false,
                                                        'icon-image': ['get', 'uploadmap']
                                                    },
                                                    'paint': {
                                                        'text-color': newColor,
                                                        'text-halo-color': '#000000',
                                                        'text-halo-width': 3
                                                    }
                                                });
                                                l++;
                                            })
                                        }                          
                                    })         
                                }
                            })
    
                            i = 5;
    
                            window.dispatchEvent(event);     
                        }
    
                    })
    
                })
                
    
    
            }   
            
            function converter(val){
                if(val == null){
                    return ""
                } else {
                    return val
                }
            }

            function convertPoint(point, center, dx, dy) {
                var newPoint = [];

                newPoint[0] = (point[0] - Number(center[0]))*dx + Number(center[0]);
                newPoint[1] = (point[1] - Number(center[1]))*dy + Number(center[1]);
                return newPoint;
            }
    
            function transformCoordinates(jsonObj, center, dx, dy){
                console.log("Features:")
                jsonObj.features.forEach(function(feature){
                    if (!feature.geometry.coordinates[0][0]){
                        var point = feature.geometry.coordinates
                        feature.geometry.coordinates = convertPoint(point, center, dx, dy);
                    } else if(!feature.geometry.coordinates[0][0][0]) {
                        //console.log("Second condition");
                        for(let i = 0; i < feature.geometry.coordinates.length; i++){
                            var point = feature.geometry.coordinates[i];
                            feature.geometry.coordinates[i] = convertPoint(point, center, dx, dy);
                        }
                    } else {
                        //console.log("Third condition")
                        for(let i = 0; i < feature.geometry.coordinates.length; i++){
                            for(let j = 0; j < feature.geometry.coordinates[i].length; j++){
                                var point = feature.geometry.coordinates[i][j];
                                feature.geometry.coordinates[i][j] = convertPoint(point, center, dx, dy);
                            }
                        }
                    }
                    
        
                })
    
            }
    
        }
        
        if ( document.querySelector('body.sites.details')) {
            document.getElementsByClassName("map")[0].id = Date.now()
            const map_container = document.getElementsByClassName("map")[0]
            const map = new mapboxgl.Map({
                container: map_container.id, // container ID
                style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
                center: [map_container.dataset.longitude, map_container.dataset.latitude], // starting position [lng, lat]
                zoom: 16, // starting zoom
                preserveDrawingBuffer: true
            });
            map.on('load', function () {
                $('a[data-toggle="tab"]').on('shown.bs.tab', function(){
                    map.resize();
                });
            });
        }
    
        if ( document.querySelector('body.sites.details')) {
            console.log("TEST 2")
            document.getElementsByClassName("map")[0].id = Date.now()
            const map_container = document.getElementsByClassName("map")[0]
            const map = new mapboxgl.Map({
                container: map_container.id, // container ID
                style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
                center: [map_container.dataset.longitude, map_container.dataset.latitude], // starting position [lng, lat]
                zoom: 16, // starting zoom
                preserveDrawingBuffer: true
            });
            map.on('load', function () {
                $('a[data-toggle="tab"]').on('shown.bs.tab', function(){
                    map.resize();
                });
            });
        }
    
    });
    